import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RmvdDatePipeModule } from '../../pipes/rmvdDate.pipe';
import { ActionMenuComponentModule } from '../action-menu/action-menu.component.module';
import { DataObjectIconComponentModule } from '../dataobject-icon/dataobject-icon.component';
import { TimeseriesPreviewComponentModule } from '../timeseries-preview/timeseries-preview.component.module';
import { HistoricDatasetGenerationProfilePreviewComponent } from './historic-dataset-generation-profile-preview.component';

@NgModule({
  declarations: [HistoricDatasetGenerationProfilePreviewComponent],
  exports: [HistoricDatasetGenerationProfilePreviewComponent],
  imports: [
    CommonModule,
    ActionMenuComponentModule,
    DataObjectIconComponentModule,
    TimeseriesPreviewComponentModule,
    RmvdDatePipeModule,
  ],
})
export class HistoricDatasetGenerationProfilePreviewComponentModule {}
