import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import 'ag-grid-enterprise';
import { CoreModule } from './core.module';

@NgModule({
  declarations: [AppComponent],
  imports: [AppRoutingModule, CoreModule], // CoreModule - Singleton objects (services, components that are loaded only once)
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
