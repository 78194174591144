import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { ExportTimeseriesModalComponent } from './export-timeseries-modal.component';


@NgModule({
  declarations: [ExportTimeseriesModalComponent],
  exports: [ExportTimeseriesModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule
  ],
})
export class ExportTimeseriesComponentModule {}
