<ng-container *ngIf="viewModel as viewModel">
  <div class="inline-flex rounded-md shadow-sm" (clickOutside)="close()">
    <a
      [routerLink]="$any(viewModel.action)"
      routerLinkActive="bg-blue-700"
      class="text-white hover:bg-blue-500 hover:bg-opacity-75 px-3 py-2 rounded-md text-sm font-medium"
      *ngIf="!isCallbackAction(viewModel.action)"
      >{{ viewModel.title }}
    </a>
    <button
      class="text-white hover:bg-blue-500 hover:bg-opacity-75 px-3 py-2 rounded-md text-sm font-medium"
      *ngIf="isCallbackAction(viewModel.action)"
      (click)="onClick(viewModel.action)"
    >
      {{ viewModel.title }}
    </button>
    <div class="relative -ml-px block">
      <button
        type="button"
        class="relative inline-flex items-center rounded-r-md text-white bg-uniper px-1 py-2 text-sm font-medium hover:bg-blue-500 hover:bg-opacity-75"
        id="option-menu-button"
        [attr.aria-expanded]="show ? true : false"
        aria-haspopup="true"
        (click)="toggle()"
        [ngClass]="{ 'bg-blue-500': show }"
      >
        <span class="sr-only">Open options</span>
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div
        class="absolute right-0 z-10 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="option-menu-button"
        *ngIf="show"
      >
        <div class="py-1 cursor-pointer select-none flex" role="none">
          <ng-container
            *ngFor="let dropdownOption of viewModel.dropdownOptions"
          >
            <button
              class="text-gray-700 block px-4 py-2 text-sm font-bold hover:text-white hover:bg-uniper flex-grow text-left"
              role="menuitem"
              *ngIf="isCallbackAction(dropdownOption.action)"
              (click)="onClick(dropdownOption.action)"
            >
              {{ dropdownOption.title }}
            </button>
            <a
              class="text-gray-700 block px-4 py-2 text-sm font-bold hover:text-white hover:bg-uniper flex-grow text-left"
              role="menuitem"
              *ngIf="!isCallbackAction(dropdownOption.action)"
              [routerLink]="$any(dropdownOption.action)"
            >
              {{ dropdownOption.title }}
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
