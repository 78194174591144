import { isEmptyNullOrUndefined } from '../shared/utils/utils';

export interface TimeseriesItem {
  timestamp: string;
  value: number;
}

export type Timeseries = Array<TimeseriesItem>;

export type TimeseriesGranularity =
  | 'Hourly'
  | 'Daily'
  | 'Weekly'
  | 'Monthly'
  | 'Quarterly'
  | 'Yearly';

export enum TimeseriesGranularityEnum {
  Hourly = 'Hourly',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly',
}

export interface TimeseriesPreviewDetails {
  revisionId: string;
  id: string;
  timezone: string;
  uom: string;
  granularity: string;
}

export function isTimeseriesPreviewDetails(
  value: unknown
): value is TimeseriesPreviewDetails {
  return (
    !isEmptyNullOrUndefined((value as TimeseriesPreviewDetails)?.revisionId) &&
    !isEmptyNullOrUndefined((value as TimeseriesPreviewDetails)?.id)
    // TODO implement check that timezone is NOT empty, null or undefined. For now for backward compatability we're not checking this.
  );
}
