import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  catchError,
  combineLatest,
  filter,
  map,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { UnitOfMeasureReference } from 'src/app/models/unitOfMeasure.models';
import { ValuationRunPriceCurve } from 'src/app/models/valuationRunPriceCurve.model';
import { KeyValuePair } from '../../models/keyValuePair.model';
import { ModelCalibration } from '../../models/modelCalibration.model';
import { ReferenceDataState } from '../../models/referenceData.model';
import {
  isRemoteDataLoaded,
  RemoteData,
  remoteDataError,
  remoteDataLoaded,
  remoteDataLoading,
  remoteDataNotFetched,
} from '../../models/remoteData.model';
import { VolatilityReference } from '../../models/volatilityreference.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataStore extends ComponentStore<ReferenceDataState> {
  // Signals (Trigger a state change or a SideEffect)
  private reloadSubject = new Subject<void>();

  // ************ Selectors **********
  valuationRunPriceCurves$ = this.select(
    (state: ReferenceDataState) => state.valuationRunPriceCurvesRemoteData
  ).pipe(
    map((remoteData) =>
      isRemoteDataLoaded(remoteData) ? remoteData.data : null
    ),
    filter((curves) => !!curves)
  ) as Observable<ValuationRunPriceCurve[]>;

  modelCalibrations$ = this.select(
    (state: ReferenceDataState) => state.modelCalibrationRemoteData
  ).pipe(
    map((modelCalibrationRemoteData) =>
      isRemoteDataLoaded(modelCalibrationRemoteData)
        ? modelCalibrationRemoteData.data
        : null
    ),
    filter((modelCalibrations) => !!modelCalibrations)
  ) as Observable<ModelCalibration[]>;

  volatilityReferences$ = this.select(
    (state: ReferenceDataState) => state.volatilitiesRemoteData
  ).pipe(
    map((volatilitiesRemoteData) =>
      isRemoteDataLoaded(volatilitiesRemoteData)
        ? volatilitiesRemoteData.data
        : null
    ),
    filter((volatilityReferences) => !!volatilityReferences)
  ) as Observable<VolatilityReference[]>;

  calculationSpecs$ = this.select(
    (state: ReferenceDataState) => state.calculationSpecsRemoteData
  ).pipe(
    map((calculationSpecsRemoteData) =>
      isRemoteDataLoaded(calculationSpecsRemoteData)
        ? calculationSpecsRemoteData.data
        : null
    ),
    filter((calculationSpecs) => !!calculationSpecs)
  ) as Observable<KeyValuePair[]>;

  currencies$ = this.select(
    (state: ReferenceDataState) => state.currenciesRemoteData
  ).pipe(
    map((currenciesRemoteData) =>
      isRemoteDataLoaded(currenciesRemoteData)
        ? currenciesRemoteData.data
        : null
    ),
    filter((currencies) => !!currencies)
  ) as Observable<string[]>;

  calendars$ = this.select(
    (state: ReferenceDataState) => state.calendarsRemoteData
  ).pipe(
    map((calendarsRemoteData) =>
      isRemoteDataLoaded(calendarsRemoteData) ? calendarsRemoteData.data : null
    ),
    filter((calendars) => !!calendars)
  ) as Observable<string[]>;

  timezones$ = this.select(
    (state: ReferenceDataState) => state.timezonesRemoteData
  ).pipe(
    map((timezonesRemoteData) =>
      isRemoteDataLoaded(timezonesRemoteData) ? timezonesRemoteData.data : null
    ),
    filter((timezones) => !!timezones)
  ) as Observable<string[]>;

  timeseriesGranularity$ = this.select(
    (state: ReferenceDataState) => state.timeseriesGranularityRemoteData
  ).pipe(
    map((timeseriesGranularityRemoteData) =>
      isRemoteDataLoaded(timeseriesGranularityRemoteData)
        ? timeseriesGranularityRemoteData.data
        : null
    ),
    filter((timeseriesGranularity) => !!timeseriesGranularity)
  ) as Observable<string[]>;

  regressionTypes$ = this.select(
    (state: ReferenceDataState) => state.regressionTypesRemoteData
  ).pipe(
    map((regressionTypesRemoteData) =>
      isRemoteDataLoaded(regressionTypesRemoteData)
        ? regressionTypesRemoteData.data
        : null
    ),
    filter((regressionTypes) => !!regressionTypes)
  ) as Observable<KeyValuePair[]>;

  unitsOfMeasure$ = this.select(
    (state: ReferenceDataState) => state.unitsOfMeasureRemoteData
  ).pipe(
    map((unitsOfMeasureRemoteData) =>
      isRemoteDataLoaded(unitsOfMeasureRemoteData)
        ? unitsOfMeasureRemoteData.data
        : null
    ),
    filter((unitsOfMeasureRemoteData) => !!unitsOfMeasureRemoteData)
  ) as Observable<UnitOfMeasureReference[]>;

  userGeneratedLocations$ = this.select(
    (state: ReferenceDataState) => state.userGeneratedLocationsRemoteData
  ).pipe(
    map((remoteData) =>
      isRemoteDataLoaded(remoteData) ? remoteData.data : null
    ),
    filter((remoteData) => !!remoteData)
  ) as Observable<string[]>;

  markets$ = this.select(
    (state: ReferenceDataState) => state.marketsRemoteData
  ).pipe(
    map((remoteData) =>
      isRemoteDataLoaded(remoteData) ? remoteData.data : null
    ),
    filter((remoteData) => !!remoteData)
  ) as Observable<KeyValuePair<string>[]>;

  referenceDataLoaded$ = combineLatest([
    this.regressionTypes$,
    this.timeseriesGranularity$,
    this.timezones$,
    this.calendars$,
    this.currencies$,
    this.calculationSpecs$,
    this.volatilityReferences$,
    this.valuationRunPriceCurves$,
    this.modelCalibrations$,
    this.unitsOfMeasure$,
    this.userGeneratedLocations$,
    this.markets$,
  ]).pipe(
    map(() => true),
    take(1)
  );

  // *********** Updaters *********** //
  public updateValuationRunPriceCurvesRemoteState = this.updater(
    (
      state: ReferenceDataState,
      valuationRunPriceCurvesRemoteData: RemoteData<ValuationRunPriceCurve[]>
    ) => {
      return {
        ...state,
        valuationRunPriceCurvesRemoteData: valuationRunPriceCurvesRemoteData,
      };
    }
  );

  private updateVolatilitiesRemoteData = this.updater(
    (
      state: ReferenceDataState,
      volatilitiesRemoteData: RemoteData<VolatilityReference[]>
    ) => {
      return {
        ...state,
        volatilitiesRemoteData,
      };
    }
  );

  private updateModelCalibrationRemoteData = this.updater(
    (
      state: ReferenceDataState,
      modelCalibrationRemoteData: RemoteData<ModelCalibration[]>
    ) => {
      return {
        ...state,
        modelCalibrationRemoteData,
      };
    }
  );

  private updatetimezonesRemoteData = this.updater(
    (state: ReferenceDataState, timezonesRemoteData: RemoteData<string[]>) => {
      return {
        ...state,
        timezonesRemoteData,
      };
    }
  );

  private updateTimeseriesGranularityRemoteData = this.updater(
    (
      state: ReferenceDataState,
      timeseriesGranularityRemoteData: RemoteData<string[]>
    ) => {
      return {
        ...state,
        timeseriesGranularityRemoteData,
      };
    }
  );

  private updateRegressionTypesRemoteData = this.updater(
    (
      state: ReferenceDataState,
      regressionTypesRemoteData: RemoteData<KeyValuePair[]>
    ) => {
      return {
        ...state,
        regressionTypesRemoteData,
      };
    }
  );

  private updateCalculationSpecsRemoteData = this.updater(
    (
      state: ReferenceDataState,
      calculationSpecsRemoteData: RemoteData<KeyValuePair[]>
    ) => {
      return {
        ...state,
        calculationSpecsRemoteData,
      };
    }
  );

  private updateMarketsRemoteData = this.updater(
    (
      state: ReferenceDataState,
      marketsRemoteData: RemoteData<KeyValuePair[]>
    ) => {
      return {
        ...state,
        marketsRemoteData,
      };
    }
  );

  private updateCurrenciesRemoteData = this.updater(
    (state: ReferenceDataState, currenciesRemoteData: RemoteData<string[]>) => {
      return {
        ...state,
        currenciesRemoteData,
      };
    }
  );

  private updateCalendarsRemoteData = this.updater(
    (state: ReferenceDataState, calendarsRemoteData: RemoteData<string[]>) => {
      return {
        ...state,
        calendarsRemoteData,
      };
    }
  );

  private updateUnitsOfMeasureRemoteData = this.updater(
    (
      state: ReferenceDataState,
      unitsOfMeasureRemoteData: RemoteData<UnitOfMeasureReference[]>
    ) => {
      return {
        ...state,
        unitsOfMeasureRemoteData,
      };
    }
  );

  private updateUserGeneratedLocationsRemoteData = this.updater(
    (
      state: ReferenceDataState,
      userGeneratedLocationsRemoteData: RemoteData<string[]>
    ) => {
      return {
        ...state,
        userGeneratedLocationsRemoteData,
      };
    }
  );

  // *********** Side Effects *********** //
  private fetchTimezonesSideEffect = this.effect(
    (trigger$: Observable<void>) => {
      return trigger$.pipe(
        tap(() => this.updatetimezonesRemoteData(remoteDataLoading())),
        switchMap(() => this.apiService.GetTimezones()),
        tap((timezones) =>
          this.updatetimezonesRemoteData(remoteDataLoaded(timezones))
        ),
        catchError((error: HttpErrorResponse) => {
          this.updatetimezonesRemoteData(remoteDataError(error));
          return of([]);
        })
      );
    }
  )(this.getLoadSignal());

  private fetchCalendarsSideEffect = this.effect(
    (trigger$: Observable<void>) => {
      return trigger$.pipe(
        tap(() => this.updateCalendarsRemoteData(remoteDataLoading())),
        switchMap(() => this.apiService.GetCalendars()),
        tap((data) => this.updateCalendarsRemoteData(remoteDataLoaded(data))),
        catchError((error: HttpErrorResponse) => {
          this.updateCalendarsRemoteData(remoteDataError(error));
          return of([]);
        })
      );
    }
  )(this.getLoadSignal());

  private fetchCurrenciesSideEffect = this.effect(
    (trigger$: Observable<void>) => {
      return trigger$.pipe(
        tap(() => this.updateCurrenciesRemoteData(remoteDataLoading())),
        switchMap(() => this.apiService.GetCurrencies()),
        tap((data) => this.updateCurrenciesRemoteData(remoteDataLoaded(data))),
        catchError((error: HttpErrorResponse) => {
          this.updateCurrenciesRemoteData(remoteDataError(error));
          return of([]);
        })
      );
    }
  )(this.getLoadSignal());

  private fetchCalculationSpecsSideEffect = this.effect(
    (trigger$: Observable<void>) => {
      return trigger$.pipe(
        tap(() => this.updateCalculationSpecsRemoteData(remoteDataLoading())),
        switchMap(() => this.apiService.GetCalculationSpecs()),
        tap((data) =>
          this.updateCalculationSpecsRemoteData(remoteDataLoaded(data))
        ),
        catchError((error: HttpErrorResponse) => {
          this.updateCalculationSpecsRemoteData(remoteDataError(error));
          return of([]);
        })
      );
    }
  )(this.getLoadSignal());

  private fetchRegressionTypesSideEffect = this.effect(
    (trigger$: Observable<void>) => {
      return trigger$.pipe(
        tap(() => this.updateRegressionTypesRemoteData(remoteDataLoading())),
        switchMap(() => this.apiService.GetRegressionFunctions()),
        tap((data) =>
          this.updateRegressionTypesRemoteData(remoteDataLoaded(data))
        ),
        catchError((error: HttpErrorResponse) => {
          this.updateRegressionTypesRemoteData(remoteDataError(error));
          return of([]);
        })
      );
    }
  )(this.getLoadSignal());

  private fetchMarketsSideEffect = this.effect((trigger$: Observable<void>) => {
    return trigger$.pipe(
      tap(() => this.updateMarketsRemoteData(remoteDataLoading())),
      switchMap(() => this.apiService.GetMarkets()),
      tap((data) => this.updateMarketsRemoteData(remoteDataLoaded(data))),
      catchError((error: HttpErrorResponse) => {
        this.updateMarketsRemoteData(remoteDataError(error));
        return of([]);
      })
    );
  })(this.getLoadSignal());

  private fetchTimeseriesGranularitySideEffect = this.effect(
    (trigger$: Observable<void>) => {
      return trigger$.pipe(
        tap(() =>
          this.updateTimeseriesGranularityRemoteData(remoteDataLoading())
        ),
        switchMap(() => this.apiService.GetTimeSeriesGranularity()),
        tap((data) =>
          this.updateTimeseriesGranularityRemoteData(remoteDataLoaded(data))
        ),
        catchError((error: HttpErrorResponse) => {
          this.updateTimeseriesGranularityRemoteData(remoteDataError(error));
          return of([]);
        })
      );
    }
  )(this.getLoadSignal());

  private fetchValuationRunPriceCurvesSideEffect = this.effect(
    (trigger$: Observable<void>) => {
      return trigger$.pipe(
        tap(() =>
          this.updateValuationRunPriceCurvesRemoteState(remoteDataLoading())
        ),
        switchMap(() => this.apiService.GetValuationRunPriceCurves()),
        tap((curves) =>
          this.updateValuationRunPriceCurvesRemoteState(
            remoteDataLoaded(curves)
          )
        ),
        catchError((error: HttpErrorResponse) => {
          this.updateValuationRunPriceCurvesRemoteState(remoteDataError(error));
          return of([]);
        })
      );
    }
  )(this.getLoadSignal());

  private fetchVolatilitiesReferencesSideEffect = this.effect(
    (trigger$: Observable<void>) => {
      return trigger$.pipe(
        tap(() => this.updateVolatilitiesRemoteData(remoteDataLoading())),
        switchMap(() => this.apiService.GetVolatalityReferences()),
        tap((volatilitiesReferences) =>
          this.updateVolatilitiesRemoteData(
            remoteDataLoaded(volatilitiesReferences)
          )
        ),
        catchError((error: HttpErrorResponse) => {
          this.updateVolatilitiesRemoteData(remoteDataError(error));
          return of([]);
        })
      );
    }
  )(this.getLoadSignal());

  private fetchModelCalibrationsSideEffect = this.effect(
    (trigger$: Observable<void>) => {
      return trigger$.pipe(
        tap(() => this.updateModelCalibrationRemoteData(remoteDataLoading())),
        switchMap(() => this.apiService.GetModelCalibrations()),
        tap((modelCalibrations) =>
          this.updateModelCalibrationRemoteData(
            remoteDataLoaded(modelCalibrations)
          )
        ),
        catchError((error: HttpErrorResponse) => {
          this.updateModelCalibrationRemoteData(remoteDataError(error));
          return of([]);
        })
      );
    }
  )(this.getLoadSignal());

  private fetchUnitsOfMeasureSideEffect = this.effect(
    (trigger$: Observable<void>) => {
      return trigger$.pipe(
        tap(() => this.updateUnitsOfMeasureRemoteData(remoteDataLoading())),
        switchMap(() => this.apiService.GetUnitsOfMeasure()),
        tap((unitsOfMeasure) =>
          this.updateUnitsOfMeasureRemoteData(remoteDataLoaded(unitsOfMeasure))
        ),
        catchError((error: HttpErrorResponse) => {
          this.updateUnitsOfMeasureRemoteData(remoteDataError(error));
          return of([]);
        })
      );
    }
  )(this.getLoadSignal());

  private fetchUserGeneratedLocationsSideEffect = this.effect(
    (trigger$: Observable<void>) => {
      return trigger$.pipe(
        tap(() =>
          this.updateUserGeneratedLocationsRemoteData(remoteDataLoading())
        ),
        switchMap(() => this.apiService.GetUserGeneratedData('locations')),
        tap((userGeneratedLocations) =>
          this.updateUserGeneratedLocationsRemoteData(
            remoteDataLoaded(userGeneratedLocations)
          )
        ),
        catchError((error: HttpErrorResponse) => {
          this.updateUserGeneratedLocationsRemoteData(remoteDataError(error));
          return of([]);
        })
      );
    }
  )(this.getLoadSignal());

  constructor(private apiService: ApiService) {
    super({
      timezonesRemoteData: remoteDataNotFetched(),
      valuationRunPriceCurvesRemoteData: remoteDataNotFetched(),
      volatilitiesRemoteData: remoteDataNotFetched(),
      modelCalibrationRemoteData: remoteDataNotFetched(),
      timeseriesGranularityRemoteData: remoteDataNotFetched(),
      regressionTypesRemoteData: remoteDataNotFetched(),
      calculationSpecsRemoteData: remoteDataNotFetched(),
      currenciesRemoteData: remoteDataNotFetched(),
      calendarsRemoteData: remoteDataNotFetched(),
      unitsOfMeasureRemoteData: remoteDataNotFetched(),
      userGeneratedLocationsRemoteData: remoteDataNotFetched(),
      marketsRemoteData: remoteDataNotFetched(),
    });
  }

  reload() {
    this.reloadSubject.next();
  }

  private getLoadSignal(): Observable<void> {
    return merge(of(undefined), this.reloadSubject);
  }
}
