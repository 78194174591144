import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { TimeagoModule } from 'ngx-timeago';
import { BackgroundJobsIconComponentModule } from '../background-jobs-Icon/background-jobs-Icon.component.module';
import { LoggedOnUserComponent } from './loggedonuser.component';

@NgModule({
  declarations: [LoggedOnUserComponent],
  exports: [LoggedOnUserComponent],
  imports: [
    CommonModule,
    BackgroundJobsIconComponentModule,
    TimeagoModule,
    NgClickOutsideDirective,
    RouterModule,
  ],
})
export class LoggedOnUserComponentModule {}
