import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { DatePickerOptions } from 'src/app/models/dateFormlyFieldInput.model';

@Component({
  selector: 'formly-field-datepicker',
  template: `
    <input
      [class.input-is-invalid]="showError"
      type="text"
      [formControl]="formControl"
      [matDatepicker]="picker"
      [matDatepickerFilter]="to['datepickerOptions']?.filter"
      [max]="to['datepickerOptions']?.max"
      [min]="to['datepickerOptions']?.min"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder ?? ''"
      (click)="picker.open()"
      class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md read-only:bg-gray-100"
    />
    <mat-datepicker
      #picker
      [dateClass]="to['datepickerOptions']?.dateClass"
      [disabled]="to['datepickerOptions']?.disabled || to.readonly"
      [opened]="to['datepickerOptions']?.opened"
      [panelClass]="panelClass"
      [startAt]="to['datepickerOptions']?.startAt"
      [startView]="to['datepickerOptions']?.startView"
      [touchUi]="to['datepickerOptions']?.touchUi"
    >
    </mat-datepicker>
  `,
})
export class DateFormlyFieldInput extends FieldType<FieldTypeConfig> {
  override defaultOptions = {
    templateOptions: {
      datepickerOptions: {
        startView: 'month',
      } as DatePickerOptions,
    },
  };

  get panelClass(): string[] {
    if (this.to['datepickerOptions']?.panelClass) {
      if (Array.isArray(this.to['datepickerOptions']?.panelClass)) {
        (this.to['datepickerOptions'].panelClass as string[]).push(
          'rmvd-date-picker'
        );
        return this.to['datepickerOptions'].panelClass;
      }
      return [this.to['datepickerOptions'].panelClass, 'rmvd-date-picker'];
    }

    return ['rmvd-date-picker'];
  }
}

@NgModule({
  declarations: [DateFormlyFieldInput],
  exports: [DateFormlyFieldInput],
  imports: [
    CommonModule,
    FormlyModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    ReactiveFormsModule,
  ],
})
export class DateFormlyFieldInputModule {}
