import { createAction, createReducer, createFeatureSelector, on, props } from '@ngrx/store';
import { BackgroundJobDataObject } from '../models/backgroundJobs.model';
import { Tooltip } from '../shared/components/tooltip.state';

// STATE

export interface BackgroundJobUpdateDetail
{
    notRead: boolean;
    job: BackgroundJobDataObject;
    timestampReceived: Date;
}

export interface BackgroundJobUpdates 
{
    jobs: BackgroundJobUpdateDetail[];
    isVisible: boolean;
    hasUnreadUpdates: boolean;
}

// ACTIONS

export const notifyOfUpdates = createAction(
    '[Background Jobs] Updates Notification', 
    props<{updates: BackgroundJobDataObject[]}>()
    );

export const closeUpdates = createAction('[Background Jobs] Close')

export const toggleUpdates = createAction('[Background Jobs] Toggle')

export const toolTipsData = createAction(
    '[Tooltip Jobs] Updates Notification', 
    props<{toolTipsData: Tooltip[]}>()
    );

export const clearAll = createAction( '[Background Jobs] Clear All');

// REDUCERS

export const initialState = {jobs: [] as BackgroundJobUpdateDetail[], isVisible: false, hasUnreadUpdates: false};

export const initialTooltipState = {toolTipsData: [] as any};

export const backgrounJobUpdatesReducer = createReducer(
    initialState,
    on(notifyOfUpdates, (state, {updates}) => ({
        ...state, 
        jobs: [
            ...updates.map(j => ({notRead: true, job: j, timestampReceived: new Date})), // new updates
            ...state.jobs.filter(j => !updates.map(u => u.id).includes(j.job.id)) // old (existing updates) less new updates
        ],
        hasUnreadUpdates: true,
        })
    ),
    on(closeUpdates, (state) => ({
        ...state, 
        jobs: state.jobs.map(j => ({...j, notRead:false})),
        hasUnreadUpdates: false, 
        isVisible: false
        })
    ),
    on(toggleUpdates, (state) => ({
        ...state,
        isVisible: !state.isVisible,
        hasUnreadUpdates: state.isVisible ? false : state.hasUnreadUpdates,
        })
    ),
    on(clearAll, (state) => ({
        ...state,
        hasUnreadUpdates: false,
        jobs: [] as BackgroundJobUpdateDetail[]
        })
    ),
);

export const toolTipReducer = createReducer(
    initialTooltipState,
    on(toolTipsData, (state, { toolTipsData }) => {
        return {
        ...state,
        toolTipsData: (toolTipsData && toolTipsData.length) ? toolTipsData : state.toolTipsData,
        }
    }),
);

// SELECTORS

export const selectTooltipUpdates = createFeatureSelector<Tooltip>('toolTipsData');

export const selectBackgroundJobUpdates = createFeatureSelector<BackgroundJobUpdates>('backgroundJobUpdates');