import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsModule } from '../icons/icons.component.module';
import { BackgroundJobsIconComponent } from './background-jobs-Icon.component';

@NgModule({
  declarations: [BackgroundJobsIconComponent],
  exports: [BackgroundJobsIconComponent],
  imports: [CommonModule, IconsModule],
})
export class BackgroundJobsIconComponentModule {}
