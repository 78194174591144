import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-input',
  template: `
    <input
      [type]="to.type"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [class.input-is-invalid]="showError"
      [placeholder]="to.placeholder ?? ''"
      class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md read-only:bg-gray-100"
    />
    <span *ngIf="to['subtext']" class="text-sm italic max-w-fit">{{
      to['subtext']
    }}</span>
  `,
})
export class InputFormlyFieldInput extends FieldType<FieldTypeConfig> {
  get type() {
    return this.to.type || 'text';
  }
  toolTipStyle: any = 'MyLabel';
}

@NgModule({
  declarations: [InputFormlyFieldInput],
  exports: [InputFormlyFieldInput],
  imports: [CommonModule, FormlyModule, ReactiveFormsModule],
})
export class InputFormlyFieldInputModule {}
