import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActionButtonWithDropdownViewModel } from 'src/app/models/ActionButtonWithDropdown.model';

@Component({
  selector: 'app-action-button-with-dropdown',
  templateUrl: './action-button-with-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonWithDropdownComponent {
  @Input() viewModel: ActionButtonWithDropdownViewModel | null = null;
  show: boolean = false;

  toggle(): void {
    typeof this.viewModel?.dropdownOptions[0].action === 'string';
    this.show = !this.show;
  }

  close(): void {
    this.show = false;
  }

  isCallbackAction(action: (() => void) | string) {
    return typeof action === 'function';
  }

  onClick(action: (() => void) | string) {
    if (typeof action === 'function') {
      action();
    }
  }
}
