import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { TimeseriesPreviewComponentModule } from '../components/timeseries-preview/timeseries-preview.component.module';
import { isTimeseriesPreviewDetails } from '../../models/timeseries.model';

@Component({
  selector: 'formly-field-timeseries-preview',
  template: `
    <ng-container
      *ngIf="
        formControl?.value && isTimeseriesPreviewDetails(formControl?.value)
      "
    >
      <div class="{{ field.className }}">
        <div class="h-72 w-full bg-white shadow sm:rounded-lg drop-shadow-md">
          <app-timeseries-preview [object]="formControl.value">
          </app-timeseries-preview>
        </div>
      </div>
    </ng-container>
  `,
})
export class TimeseriesPreviewFormlyField extends FieldType<FieldTypeConfig> {
  isTimeseriesPreviewDetails = isTimeseriesPreviewDetails;
}

@NgModule({
  declarations: [TimeseriesPreviewFormlyField],
  exports: [TimeseriesPreviewFormlyField],
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    TimeseriesPreviewComponentModule,
  ],
})
export class TimeseriesPreviewFormlyFieldModule {}
