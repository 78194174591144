import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ActionMenuComponent } from './action-menu.component';

@NgModule({
  declarations: [ActionMenuComponent],
  exports: [ActionMenuComponent],
  imports: [CommonModule, NgClickOutsideDirective],
})
export class ActionMenuComponentModule {}
