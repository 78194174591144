import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridBackgroundJobsIconComponentModule } from 'src/app/shared/components/aggrid-background-jobs-Icon/aggrid-background-jobs-Icon.component.module';
import { IconsModule } from 'src/app/shared/components/icons/icons.component.module';
import { BackgroundJobsGridComponent } from './background-jobs-grid.component';

@NgModule({
  declarations: [BackgroundJobsGridComponent],
  exports: [BackgroundJobsGridComponent],
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    AgGridModule,
    IconsModule,
    AgGridBackgroundJobsIconComponentModule,
  ],
})
export class BackgroundJobsGridComponentModule {}
