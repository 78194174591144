import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  delay,
  distinctUntilChanged,
  filter,
  map,
  scan,
  withLatestFrom,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  delayToShowHideLoader = 500;
  private loadingSubject = new BehaviorSubject(false);

  private busyCounter$ = this.loadingSubject.pipe(
    scan((acc, value) => {
      if (value) {
        if (acc <= 0) {
          return 1;
        }
        return acc + 1;
      } else {
        if (acc > 0) {
          return acc - 1;
        }
        return 0;
      }
    }, 0)
  );

  loading$ = this.busyCounter$.pipe(
    filter((counter) => counter === 0 || counter === 1),
    distinctUntilChanged(),
    delay(this.delayToShowHideLoader),
    withLatestFrom(this.busyCounter$),
    map(([delayedCount, latestCount]) => {
      if (delayedCount) {
        if (latestCount === 0) {
          return false;
        }
        // Only show if we have an item in the queue after the delay time
        return true;
      } else {
        if (latestCount > 0) {
          return true;
        }
        // Make sure queue is still 0 since a new XHR request may have come in
        // while timer was running
        return false;
      }
    }),
    distinctUntilChanged()
  );

  start() {
    this.loadingSubject.next(true);
  }

  stop() {
    this.loadingSubject.next(false);
  }
}
