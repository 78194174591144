export const environment = {
  production: true,
  clientId: '__#{ClientId}#__',
  authority: '__#{Authority}#__',
  redirectUri: '__#{RedirectUri}#__',
  apiUrl: '__#{ApiUrl}#__',
  scope: ['__#{Scope}#__'],
  scenarioNamePrefix: '__#{ScenarioNamePrefix}#__',
  buildNumber: '__#{BuildNumber}#__',
  environment: '__#{Environment}#__',
  backgroundJobsRefreshInterval: 30000,
};
