import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-upsert-object-header',
  templateUrl: './upsert-object-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertObjectHeaderComponent {
  @Input() editMode: boolean = false;
}
