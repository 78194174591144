import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RmvdDatePipeModule } from '../../pipes/rmvdDate.pipe';
import { DataObjectIconComponentModule } from '../dataobject-icon/dataobject-icon.component';
import { TagsComponentModule } from '../tags/tags.component.module';
import { TimeseriesPreviewComponentModule } from '../timeseries-preview/timeseries-preview.component.module';
import { CalculatedTimeSeriesPreviewComponent } from './calculatedtimeseries-preview.component';

@NgModule({
  declarations: [CalculatedTimeSeriesPreviewComponent],
  exports: [CalculatedTimeSeriesPreviewComponent],
  imports: [
    CommonModule,
    DataObjectIconComponentModule,
    TagsComponentModule,
    RmvdDatePipeModule,
    TimeseriesPreviewComponentModule,
  ],
})
export class CalculatedTimeSeriesPreviewComponentModule {}
