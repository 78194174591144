import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RmvdDatePipeModule } from '../../pipes/rmvdDate.pipe';
import { ActionMenuComponentModule } from '../action-menu/action-menu.component.module';
import { DataObjectIconComponentModule } from '../dataobject-icon/dataobject-icon.component';
import { TagsComponentModule } from '../tags/tags.component.module';
import { HistoricDatasetPreviewComponent } from './historic-dataset-preview.component';

@NgModule({
  declarations: [HistoricDatasetPreviewComponent],
  exports: [HistoricDatasetPreviewComponent],
  imports: [
    CommonModule,
    ActionMenuComponentModule,
    DataObjectIconComponentModule,
    TagsComponentModule,
    RmvdDatePipeModule,
  ],
})
export class HistoricDatasetPreviewComponentModule {}
