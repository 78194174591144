export class LoggedOnUser{
    constructor(n: string, e: string, roles: any) {
        this.name = n;
        this.email = e;
        this.kid = e.split("@")[0];
        this.roles = roles;
    }
    name: string;
    email: string;
    kid: string;
    roles: any;

    public get displayText() {
         return `${this.name} (${this.kid})`;
    } 
}