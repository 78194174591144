import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  map,
  of,
  pipe,
  startWith,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { Notification } from '../../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationSubject = new BehaviorSubject<Notification | null>(null);

  notification$ = this.notificationSubject
    .asObservable()
    .pipe(this.clearNotification());

  error(...message: string[]) {
    this.notificationSubject.next({
      type: 'error',
      message,
    });
  }

  success(message: string) {
    this.notificationSubject.next({
      type: 'success',
      message: [message],
    });
  }

  info(message: string) {
    this.notificationSubject.next({
      type: 'info',
      message: [message],
    });
  }

  clear() {
    this.notificationSubject.next(null);
  }

  private clearNotification(): MonoTypeOperatorFunction<Notification | null> {
    return pipe(
      switchMap((notification) => {
        return notification
          ? timer(10000).pipe(
              tap(() => this.clear()),
              map(() => null),
              startWith(notification)
            )
          : of(null);
      })
    );
  }
}
