import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalInterceptor } from '@azure/msal-angular';
import { LoadingInterceptor } from './loading.interceptor';
import { UserTimeZoneOffsetInterceptor } from './userTimezoneOffset.interceptor';

export const HTTP_INTERCEPTOR_PROVIDERS = [
  addInterceptor(MsalInterceptor),

  addInterceptor(LoadingInterceptor),

  addInterceptor(UserTimeZoneOffsetInterceptor),
];

function addInterceptor<T>(interceptor: T) {
  return {
    provide: HTTP_INTERCEPTORS,
    useClass: interceptor,
    multi: true,
  };
}
