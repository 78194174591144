import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RmvdDatePipeModule } from '../../pipes/rmvdDate.pipe';
import { ActionMenuComponentModule } from '../action-menu/action-menu.component.module';
import { DataObjectIconComponentModule } from '../dataobject-icon/dataobject-icon.component';
import { IconsModule } from '../icons/icons.component.module';
import { TimeseriesPreviewComponentModule } from '../timeseries-preview/timeseries-preview.component.module';
import { EscalatedForwardCurveReferencePreviewComponent } from './escalated-forwardcurve-reference-preview.component';

@NgModule({
  declarations: [EscalatedForwardCurveReferencePreviewComponent],
  exports: [EscalatedForwardCurveReferencePreviewComponent],
  imports: [
    CommonModule,
    ActionMenuComponentModule,
    DataObjectIconComponentModule,
    TimeseriesPreviewComponentModule,
    RmvdDatePipeModule,
    IconsModule,
  ],
})
export class EscalatedForwardCurveReferencePreviewComponentModule {}
