import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationErrorComponent } from './notification-error.component';
import { NotificationInfoComponent } from './notification-info.component';
import { NotificationSuccessComponent } from './notification-success.component';
import { NotificationComponent } from './notification.component';

@NgModule({
  declarations: [
    NotificationComponent,
    NotificationErrorComponent,
    NotificationSuccessComponent,
    NotificationInfoComponent,
  ],
  exports: [NotificationComponent],
  imports: [CommonModule],
})
export class NotificationComponentModule {}
