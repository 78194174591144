import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DataObjectTypeName, RmvdMeta } from './dataObject.model';
import { ObjectMeta } from './objectMeta.model';
import { SelectOption } from './select.model';
import { ValuationTask } from './valuationTask.model';
import { TimeseriesPreviewDetails } from './timeseries.model';
import { ValuationRunPriceCurve, ValuationRunPriceCurveRevision } from './valuationRunPriceCurve.model';

export interface DealTerm {
  id: string;
  partitionKey: string;
  objectMeta: ObjectMeta;
  rmvdMeta: RmvdMeta;
  objectType: string;
  objectBody: {
    dealInformation: DealInformation;
    deliveryTerms: DeliveryTerms;
    pricingTerms: PricingTerms;
    // pricingIndex: any;
  };
  valuationTasks: ValuationTask[];
}

export type ClonedDealTerm = Omit<DealTerm, 'id'> & { id: null };

export interface DealInformation {
  buySell: string | null;
  assetClass: string | null;
  dealVariation: string | null;
  endurDealNumber: string | null;
  tradeDate: string | null;
  counterparty: string;
  parRate: number | null;
}

export interface PricingTerms {
  fixedPrice: number | null;
  priceFloor: number | null;
  priceCap?: number | null;
  volumeFloor?: number | null;
  volumeCap?: number | null;
}

export interface DeliveryTerms {
  currency: string | null;
  renewableType: string | null;
  location: string | null;
  swapType: string | null;
  settlementPeriod?: string | null;
  contractProfile?: any;
  contractProfileId?: any;
  RevisionId?: any;
  revisionId?: any;
  contractProfileRevision?: any;
  installedCapacityAcInMw: number | null;
  degradation: number | null;
  deliveryPeriod: DeliveryPeriod;
}

export interface DeliveryPeriod {
  begin: string | null;
  end: string | null;
  granularity: string | null;
  timeZone: string | null;
  calendar: string | null;
}

export function isDealTermObject(value: unknown): value is DealTerm {
  return (value as DealTerm)?.objectType === DataObjectTypeName.DealTerm;
}

export interface CreateDealTermRequest {
  name: string;
  description: string | null;
  tags: string[];
  dealInformation: {
    buySell: string | null;
    assetClass: string;
    dealVariation: string | null;
    endurDealNumber: string | null;
    tradeDate: string | null;
    counterparty: string | null;
    parRate: number | null;
  };
  deliveryTerms: {
    currency: string | null;
    renewableType: string | null;
    location: string | null;
    swapType: string | null;
    contractProfile?: string | null;
    contractProfileRevision?: string | null;
    contractProfileId?: string | null;
    RevisionId?: string | null;
    Contract_TS?: any;
    contractQuantities?: any;
    settlementPeriod?: string | null;
    installedCapacityAcInMw: number | null;
    degradation: number | null;
    deliveryPeriod: {
      begin: string | null;
      end: string | null;
      granularity: string | null;
      timeZone: string | null;
      calendar: string | null;
    };
  };
  pricingTerms: {
    fixedPrice: number | null;
    priceFloor: number | null;
    priceCap?: number | null;
    volumeFloor?: number | null;
    volumeCap?: number | null;
  };
}

export interface UpdateDealTermRequest extends CreateDealTermRequest {
  dealTermId: string;
}

export interface ManageDealTermForm {
  dealTermId?: string | null;
  name: string | null;
  dealVariation?: string | null;
  description?: string | null;
  endurDealNumber?: string | null;
  counterparty?: string | null;
  tradeDate?: Date | null;
  buySell: string | null;
  currency: SelectOption | null;
  renewableType: string | null;
  location: string | null;
  swapType: string | null;
  contractProfile?: SelectOption | null | any;
  Contract_TS?: any;
  // contractQuantities?: any;
  // forwardPrice: any;
  // generationProfiles: any;
  // revision: SelectOption | null;
  contractProfileRevision?: SelectOption<any> | null | any;
  contractQuantities?: any;
  // forwardPrice: SelectOption<ValuationRunPriceCurve> | any;
  revision?: SelectOption<ValuationRunPriceCurveRevision> | any;
  timeseriesPreview?: TimeseriesPreviewDetails | null;
  settlementPeriod: string | null;
  installedCapacityAcInMw: number | null;
  degradation?: number | null;
  deliveryBeginDate: Date | null;
  deliveryEndDate: Date | null;
  granularity: SelectOption | null;
  timeZone: SelectOption | null;
  calendar: SelectOption | null;
  fixedPrice: number | null;
  priceFloor?: number | null;
  priceCap?: number | null;
  volumeFloor?: number | null;
  volumeCap?: number | null;
  parRate?: number | null;
  tags?: string[] | null;
  valuationTaskExists: boolean;
}

export interface ManageDealTermViewModel {
  fields: FormlyFieldConfig[];
  model: Partial<ManageDealTermForm>;
  form: FormGroup;
}

export interface ManageDealTermState {
  formlyFieldConfig: FormlyFieldConfig[] | null;
  model: Partial<ManageDealTermForm> | null;
  formGroup: FormGroup;
  contractProfiles: any;
}
