// dashboard-header.component.ts
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';
import { optionalMultiSelect } from 'src/app/shared/formly-components/formlyHelpers';
import { DashboardWidgetService } from './dashboard-widget.service';

@Component({
  selector: 'app-dashboard-header',
  template: `<!-- dashboard-header.component.html -->
  <form style="    width: fit-content;
  float: right;"
[formGroup]="form">
  <formly-form [fields]="fields" [form]="form"></formly-form>
</form>
  `
})
export class DashboardWidgetHeaderComponent implements OnInit{
    availableComponents: string[] = ['Valuation Run Results', 'Background Jobs'];
    selectedComponents: any = ['Valuation Run Results', 'Background Jobs'];

    selectedComponent: string = ''; // Store the currently selected component
    
    constructor(private dashboardWidgetService: DashboardWidgetService) {}
    form: any = new FormGroup({});

  fields: FormlyFieldConfig[] = [
    // optionalMultiSelect<any>(
    //     'componentsSelected',
    //     '',
    //     this.getOptions(),
    //     'label',
    //     undefined,
    //     {
    //       templateOptions: {
    //         placeholder: 'Select Component',
    //         closeOnSelect: false,
    //       },
    //     },
    //     '',
    //   ),
  ];

    getOptions(): Observable<any[]> {
        return of(['Valuation Run Results', 'Background Jobs']);
        // Return your observable or array of options here
        // Example:
        // return this.apiService.getOptions();
    }
  
    ngOnInit(): void {
        this.dashboardWidgetService.selectedComponents$.subscribe((components: any) => {
            console.log('components', components)
            // Initialize the form group with the selected components
            this.form = new FormGroup({
              componentsSelected: new FormControl(components),
            });
      
            // Define your Formly fields after initializing the form group
            this.fields = [
              optionalMultiSelect<any>(
                'componentsSelected',
                '',
                this.getOptions(),
                'label',
                undefined,
                {
                  templateOptions: {
                    placeholder: 'Select Component',
                    closeOnSelect: false,
                  },
                  defaultValue: this.selectedComponents,
                }
              ),
              // Add more fields as needed
            ];
            this.form.get('componentsSelected').valueChanges.subscribe((value: any) => {
                this.dashboardWidgetService.updateSelectedComponents(value);
              });
          });
    }
  
    onCheckboxChange(event: any): void {
      if (this.selectedComponent && !this.selectedComponents.includes(this.selectedComponent)) {
        this.selectedComponents.push(this.selectedComponent);
      } else if (this.selectedComponent && this.selectedComponents.includes(this.selectedComponent)) {
        this.selectedComponents = this.selectedComponents.filter((comp: any) => comp !== this.selectedComponent);
      }
      this.selectedComponent = ''; // Reset the selected component after handling the change
    }
  
    // onCheckboxChange(component: string, event: Event): void {
    //   const checkbox = event.target as HTMLInputElement;
    //   if (checkbox.checked) {
    //     this.dashboardWidgetService.updateSelectedComponents([...this.selectedComponents, component]);
    //   } else {
    //     this.dashboardWidgetService.updateSelectedComponents(this.selectedComponents.filter(c => c !== component));
    //   }
    // }
}

