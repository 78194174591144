import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgItemLabelDirective, NgSelectModule } from '@ng-select/ng-select';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'formly-field-multi-select',
  template: `
    <ng-container *ngIf="items | async as opts">
      <ng-select
        [formControl]="formControl"
        [formlyAttributes]="field"
        [items]="opts"
        [multiple]="true"
        [addTag]="to['addTag']"
        [selectOnTab]="to['selectOnTab']"
        [closeOnSelect]="to['closeOnSelect'] == false ? false : true"
        [isOpen]="to['isOpen']"
        [clearAllText]="to['clearAllText'] || 'Clear'"
        [placeholder]="to.placeholder ?? ''"
        [bindLabel]="to['bindLabel'] || 'label'"
        [class.input-error]="showError"
        [compareWith]="to['compareWith']"
      >
        <ng-template
          ng-label-tmp
          let-item="item"
          let-clear="clear"
          *ngIf="to['allowDuplicateTags']"
        >
          <span
            class="ng-value-icon left"
            (click)="clear(item)"
            aria-hidden="true"
            >×</span
          >
          <span class="ng-value-label">{{ item.tag }}</span>
        </ng-template>
      </ng-select>
      <span *ngIf="to['subtext']" class="text-sm italic">{{
        to['subtext']
      }}</span>
    </ng-container>
  `,
})
export class NgMultiSelectFormlyFieldInput extends FieldType<FieldTypeConfig> {
  public get items() {
    if (!(this.to.options instanceof Observable)) {
      return of(this.to.options);
    }
    return this.to.options;
  }
}

@NgModule({
  declarations: [NgMultiSelectFormlyFieldInput],
  exports: [NgMultiSelectFormlyFieldInput],
  imports: [CommonModule, FormlyModule, ReactiveFormsModule, NgSelectModule],
})
export class NgMultiSelectFormlyFieldInputModule {}
