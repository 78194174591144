import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { notifyOfUpdates } from '../../state/background-jobs.state';
import { UniperAuthService } from './uniperAuth.service';
import { BackgroundJobDataObject } from '../../models/backgroundJobs.model';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  constructor(private store: Store, private auth: UniperAuthService) {}

  private hubConnection: signalR.HubConnection | null = null;

  public async connect() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}backgroundjobs-hub`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    try {
      await this.hubConnection.start();
      this.hubConnection!.on('NotifyOfBackgroundJobUpdates', (jobs) => {
        const userUpdates = jobs.filter(
          (j: BackgroundJobDataObject) =>
            j.rmvdMeta.owner.toLowerCase() ===
            this.auth.LoggedOnUser?.kid.toLowerCase()
        );
        if (userUpdates.length > 0) {
          this.store.dispatch(notifyOfUpdates({ updates: userUpdates }));
        }
      });
    } catch (err) {
      console.error('Error while starting signalr connection: ' + err);
    }
  }
}
