import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: 'data-management' },
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'data-management',
    loadChildren: () =>
      import('./pages/data-management/data-management.module').then(
        (m) => m.DataManagementModule
      ),
  },
  {
    path: 'ppa-inventory',
    loadChildren: () =>
      import('./pages/ppa-inventory/ppa-inventory.module').then(
        (m) => m.PPAModule
      ),
  },
  {
    path: 'background-jobs',
    loadChildren: () =>
      import('./pages/background-jobs/background-jobs.module').then(
        (m) => m.BackgroundJobsModule
      ),
  },
  {
    path: 'add-model-caliberation',
    loadChildren: () =>
      import(
        './pages/add-model-caliberation/add-model-caliberation.module'
      ).then((m) => m.AddModelCalibrationModule),
  },
  {
    path: 'graph-basket',
    loadChildren: () =>
      import('./pages/graph-basket/graph-basket.module').then(
        (m) => m.GraphBasketModule
      ),
  },
  {
    path: 'valuationcockpit',
    loadChildren: () =>
      import('./pages/valuation-cockpit/valuation-cockpit.module').then(
        (m) => m.ValuationCockpitModule
      ),
  },
  {
    path: 'valuation-results',
    loadChildren: () =>
      import('./pages/valuation-results/valuation-results.module').then(
        (m) => m.ValuationResultsModule
      ),
  },
  // {
  //   path: 'valuation-history',
  //   loadChildren: () =>
  //     import('./pages/valuation-results/valuation-history.module').then(
  //       (m) => m.ValuationHistoryModule
  //     ),
  // },
  {
    path: 'valuationtask',
    loadChildren: () =>
      import('./pages/execute-valuationtask/execute-valuationtask.module').then(
        (m) => m.ExecuteValuationTaskModule
      ),
  },
  {
    path: 'manage',
    loadChildren: () =>
      import('./pages/manage-objects/manage-objects.module').then(
        (m) => m.ManageObjectsModule
      ),
  },
  {
    path: 'addobject',
    loadChildren: () =>
      import('./pages/add-objects/add-objects.module').then(
        (m) => m.AddObjectsModule
      ),
  },
  {
    path: 'addppaobject',
    loadChildren: () =>
      import('./pages/add-ppa-objects/add-ppa-objects.module').then(
        (m) => m.AddPPAObjectsModule
      ),
  },
  {
    path: 'ppamanageobject',
    loadChildren: () =>
      import('./pages/ppa-inventory/components/manage-ppa-objects/manage-ppa-objects.module').then(
        (m) => m.ManagePPAObjectsModule
      ),
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
