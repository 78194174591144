import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import {
  ActionButtonComponent,
  ActionButtonItemComponent,
} from './action-button.component';
import { ActionButtonDisabledComponent } from './action-button-disabled.component';

@NgModule({
  declarations: [ActionButtonComponent, ActionButtonItemComponent, ActionButtonDisabledComponent],
  exports: [ActionButtonComponent, ActionButtonDisabledComponent],
  imports: [CommonModule, NgClickOutsideDirective],
})
export class ActionButtonComponentModule {}
