import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, filter, map, Observable, of, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { withSilentLoadContext } from '../http-context-tokens/silent-load.context';
import {
  CommandResult,
  SimpleCommandResult,
} from '../../models/commandResult.model';
import {
  DataObjectTypeName,
  DataObjectTypes,
} from '../../models/dataObject.model';
import {
  CreateDealTermRequest,
  DealTerm,
  UpdateDealTermRequest,
} from '../../models/dealTerm.model';
import { ExportToAnalyticalSandboxModel } from '../../models/exportToAnalyticalSandbox.model';
import {
  ForwardCurveReference,
  UpsertForwardCurveReferenceRequest,
  UpsertForwardCurveReferenceResponse,
} from '../../models/forwardcurvereference.model';
import { Job } from '../../models/job.model';
import { KeyValuePair } from '../../models/keyValuePair.model';
import {
  TriggerModelCalibrationRequest,
  HistoricCalibrationDatasetResponse,
  MarketCalibrationDatasetResponse,
  TriggerModelCalibrationResponse,
  ModelCalibration,
  ExistingScenarioNames,
} from '../../models/modelCalibration.model';
import {
  ModelResultDataObject,
  UpdateModelResultRequest,
} from '../../models/modelResultDataObject.model';
import {
  SearchBackgroundJobsQuery,
  SearchBackgroundJobsQueryResult,
} from '../../models/searchBackgroundJobs.model';
import { SearchDataObjectModel } from '../../models/searchDataObjectResult.model';
import { Timeseries } from '../../models/timeseries.model';
import {
  AddTimeSeriesRevisionRequest,
  AddTimeSeriesRevisionResponse,
  UpdateTimeSeriesRevisionLabelsRequest,
  UpdateTimeSeriesRevisionLabelsResponse,
} from '../../models/timeSeriesRevision.model';
import { getValue } from 'src/app/shared/utils/utils';
import {
  ExecuteValuationTaskRequest,
  ExecuteValuationTaskResponse,
  ValuationRun,
} from '../../models/valuationRun.model';
import {
  CreateValuationTaskRequest,
  CreateValuationTaskResponse,
  UpdateValuationTaskRequest,
  UpdateValuationTaskResponse,
  ValuationTask,
} from '../../models/valuationTask.model';
import {
  UpsertVolatilityReferenceRequest,
  UpsertVolatilityReferenceResponse,
  VolatilityReference,
} from '../../models/volatilityreference.model';
import { BlobContainerRequest } from '../../types/azure-storage';
import { UnitOfMeasureReference } from 'src/app/models/unitOfMeasure.models';
import { ValuationRunPriceCurve } from 'src/app/models/valuationRunPriceCurve.model';
import { StartImfPercentilesCalculationRequest } from '../components/calculate-imf-percentiles-modal/calculate-imf-percentiles-models';
import {
  CreateTimeSeriesRequest,
  TimeSeriesDataObject,
  TimeSeriesTypes,
  UpdateTimeSeriesRequest,
} from 'src/app/models/timeSeriesDataObject.model';
import { ProcessTimeSeriesCsvUploadCommand } from 'src/app/models/processTimeSeriesCsvUploadCommand.model';
import { GenerationProfile } from 'src/app/models/generationProfile.model';
import {
  CreateHistoricDatasetRequest,
  UpdateHistoricDatasetRequest,
} from 'src/app/models/historicDataset.model';
import { CreateTimeseriesRevisionBasedOnReferenceRequest } from 'src/app/models/addTimeSeriesRevsionBasedOnReferenceModel';
import { ForwardCurve } from 'src/app/models/forwardcurve.model';
import {
  CreateSyntheticCurveFromProfileRequest,
  UploadProfileMatrixCsvResponse,
} from 'src/app/models/addSyntheticCurveFromProfile.model';
import { Store } from '@ngrx/store';
import { toolTipsData } from 'src/app/state/background-jobs.state';
import { AssetForm } from 'src/app/models/asset.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl!: string;

  constructor(private http: HttpClient, private store: Store) {
    this.apiUrl = environment.apiUrl;
  }

  GetCurrencies(): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.apiUrl}api/reference-data/currencies`
    );
  }

  getAllGenerationUnits(): Observable<any> {
    return this.http.get(`${this.apiUrl}api/resppa/malo/dropdown/assetgenunit`)
  }

  GetCalendars(): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.apiUrl}api/reference-data/calendars`
    );
  }

  GetTimeSeriesPreview(
    timeSeriesId: string,
    timeSeriesRevisionId: number
  ): Observable<Timeseries> {
    return this.http.get<Timeseries>(
      `${this.apiUrl}api/timeseries/${timeSeriesId}/${timeSeriesRevisionId}/preview`
    );
  }

  GetTimeSeriesForYear(
    timeSeriesId: string,
    timeSeriesRevisionId: number,
    year: number
  ): Observable<Timeseries> {
    return this.http.get<Timeseries>(
      `${this.apiUrl}api/timeseries/${timeSeriesId}/${timeSeriesRevisionId}/year/${year}`
    );
  }

  GetTimeSeriesForYearAndMonth(
    timeSeriesId: string,
    timeSeriesRevisionId: number,
    year: number,
    month: number[]
  ): Observable<Timeseries> {
    const apiUrl = `${this.apiUrl}api/timeseries/${timeSeriesId}/${timeSeriesRevisionId}/year/${year}/month/${month[0]}`;
    let params = {};

    if (month.length > 1) {
      params = {
        toMonth: month[month.length - 1],
      };
    }

    return this.http.get<Timeseries>(apiUrl, {
      params,
    });
  }

  GetTimezones(): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.apiUrl}api/reference-data/timezones`
    );
  }

  GetTimeSeriesGranularity(): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.apiUrl}api/reference-data/timeseries-granularity`
    );
  }

  GetVolatalityReferences(): Observable<VolatilityReference[]> {
    return this.http.get<VolatilityReference[]>(
      `${this.apiUrl}api/reference-data/volatility-references`
    );
  }

  GetUnitsOfMeasure(): Observable<UnitOfMeasureReference[]> {
    return this.http.get<UnitOfMeasureReference[]>(
      `${this.apiUrl}api/reference-data/uoms`
    );
  }

  GetValuationRunPriceCurves(): Observable<ValuationRunPriceCurve[]> {
    return this.http.get<ValuationRunPriceCurve[]>(
      `${this.apiUrl}api/reference-data/valuation-run-curves`
    );
  }

  GetModelCalibrations(dealTerm?: DealTerm): Observable<ModelCalibration[]> {
    let params: { [param: string]: string } = {};
    if (dealTerm) {
      params['location'] = getValue(
        dealTerm?.objectBody?.deliveryTerms?.location
      );
      params['renewableType'] = getValue(
        dealTerm?.objectBody?.deliveryTerms?.renewableType
      );
    }

    return this.http.get<ModelCalibration[]>(
      `${this.apiUrl}api/reference-data/model-calibrations`,
      {
        params: {
          ...params,
        },
      }
    );
  }

  GetRegressionFunctions(): Observable<KeyValuePair[]> {
    return this.http.get<KeyValuePair[]>(
      `${this.apiUrl}api/reference-data/regression-types`
    );
  }

  GetMarkets(): Observable<KeyValuePair[]> {
    return this.http.get<KeyValuePair[]>(
      `${this.apiUrl}api/reference-data/markets`
    );
  }

  GetCalculationSpecs(): Observable<KeyValuePair[]> {
    return this.http.get<KeyValuePair[]>(
      `${this.apiUrl}api/reference-data/calculation-specs`
    );
  }

  CreateNewTimeSeries<Type extends TimeSeriesDataObject>(
    createTimeSeriesRequest: CreateTimeSeriesRequest
  ): Observable<CommandResult<Type>> {
    return this.http.post<CommandResult<Type>>(
      `${this.apiUrl}api/timeseries/genericcurveobject`,
      createTimeSeriesRequest
    );
  }

  UpdateTimeSeries<Type extends TimeSeriesDataObject>(
    updateTimeSeriesRequest: UpdateTimeSeriesRequest
  ): Observable<CommandResult<Type>> {
    return this.http.put<CommandResult<Type>>(
      `${this.apiUrl}api/timeseries/genericcurveobject`,
      updateTimeSeriesRequest
    );
  }

  PostQueueProcessingOfTimeSeriesCsvUpload(
    cmd: ProcessTimeSeriesCsvUploadCommand
  ): Observable<Job> {
    return this.http.post<Job>(
      `${this.apiUrl}api/timeseries/genericcurveobject/queue-csvupload-processing`,
      cmd
    );
  }

  GetStorageAccountDetails(): Observable<BlobContainerRequest> {
    return this.http.get<BlobContainerRequest>(
      `${this.apiUrl}api/storageaccount/upload-details`
    );
  }

  GetNewGuid(): Observable<string> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'text/plain; charset=utf-8'
    );
    const requestOptions: Object = { headers: headers, responseType: 'text' };
    return this.http.get<string>(`${this.apiUrl}api/new-guid`, requestOptions);
  }

  GetJob(id: string, partitionKey: string): Observable<Job> {
    return this.http.get<Job>(`${this.apiUrl}api/jobs/${partitionKey}/${id}`);
  }

  SearchDataObjects(filterValue: any): Observable<SearchDataObjectModel> {
    return this.http.post<SearchDataObjectModel>(
      `${this.apiUrl}api/dataobjects`,
      { filterValues: filterValue }
    );
  }

  filterPPAObjects(filterValue: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}api/resppa/filter`,
      filterValue
    );
  }

  GetObjectList(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}api/resppa/assetList`
    );
  }

  GetAssetList(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}api/resppa/assetList`
    );
  }

  GetGenerationUnits(generationUnitId: any): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}api/resppa/malo/maloGu/${generationUnitId}`
    );
  }

  GetMaLoList(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}api/resppa/malo/maloRelation`
    );
  }

  GetDealList(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}api/resppa/dealMaster`
    );
  }

  GetPPADealDetails(ppaDealId: any): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}api/resppa/dealObject/${ppaDealId}`
    );
  }

  GetTransactionList(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}api/resppa/transaction`
    );
  }

  GetBusinessPartnersList(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}api/resppa/BusinessPartner`
    );
  }

  GetTooltips(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}api/reference-data/tool-tips`
    );
  }

  GetPPA_DROPDOWN_OPTIONS(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}api/resppa/dropdownlist/`
    );
  }

  getParentMaloOptions(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}api/resppa/malo/dropdown/maloMaster`
    );
  }

  updateTooltipData(tooltip: any) {
    if (tooltip.length > 0) {
      this.store.dispatch(toolTipsData({ toolTipsData: tooltip }));
    }
  }

  GetHistoricCalibrationDataset(): Observable<HistoricCalibrationDatasetResponse> {
    return this.http.get<HistoricCalibrationDatasetResponse>(
      `${this.apiUrl}api/calibrationsdataset/historic/`
    );
  }

  GetMarketCalibrationDataset(): Observable<MarketCalibrationDatasetResponse> {
    return this.http.get<MarketCalibrationDatasetResponse>(
      `${this.apiUrl}api/calibrationsdataset/market/`
    );
  }

  TriggerModelCalibration(
    modelCalibration: TriggerModelCalibrationRequest
  ): Observable<TriggerModelCalibrationResponse> {
    return this.http.post<TriggerModelCalibrationResponse>(
      `${this.apiUrl}api/modelcalibrations/trigger`,
      modelCalibration
    );
  }

  GetAverageCdsCurvePrice(
    curveName: string,
    startDate: string,
    endDate: string,
    asOfDate: string
  ): Observable<string> {
    return this.http
      .get<{ averageValue: string }>(
        `${this.apiUrl}api/forwardcurvereferences/GetAverageCdsCurvePrice/${curveName}/${startDate}/${endDate}/${asOfDate}`
      )
      .pipe(map((response) => response.averageValue));
  }

  ExistingScenarioNames(): Observable<ExistingScenarioNames> {
    return this.http.get<ExistingScenarioNames>(
      `${this.apiUrl}api/modelcalibrations/scenarionames`
    );
  }

  CreateDealTerm(
    createDealTermRequest: CreateDealTermRequest
  ): Observable<DealTerm> {
    return this.http.post<DealTerm>(
      `${this.apiUrl}api/dealterms`,
      createDealTermRequest
    );
  }

  CreateAsset(
    createAsset: AssetForm
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}api/resppa/assetObject`,
      createAsset
    );
  }

  CreateMaLo(
    createMaLo: any
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}api/resppa/malo`,
      createMaLo
    );
  }

  CreateTranchMaLo(
    createMaLo: any
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}api/resppa/malo/tranche`,
      createMaLo
    );
  }

  CreateTransaction(
    createTransaction: any
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}api/resppa/transaction`,
      createTransaction
    );
  }

  CreateGenerationUnit(
    createGenerationUnit: any
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}api/resppa/generationunit/`,
      createGenerationUnit
    );
  }

  CreatePPADeal(
    createPPADealRequest: any
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}api/resppa/dealObject`,
      createPPADealRequest
    );
  }


  GetDealTerm(id: string): Observable<DealTerm> {
    return this.http.get<DealTerm>(`${this.apiUrl}api/dealterms/${id}`);
  }

  GetAssetOwner(): Observable<any> {
    return this.http.get<DealTerm>(`${this.apiUrl}api/resppa/businessPartnerList`);
  }

  GetValuationTask(
    dealTermId: string,
    valuationTaskId: string
  ): Observable<ValuationTask> {
    return this.http.get<ValuationTask>(
      `${this.apiUrl}api/valuationtasks/${dealTermId}/${valuationTaskId}`
    );
  }

  CreateValuationTask(
    createValuationTaskRequest: CreateValuationTaskRequest
  ): Observable<CreateValuationTaskResponse> {
    return this.http.post<CreateValuationTaskResponse>(
      `${this.apiUrl}api/valuationtasks`,
      createValuationTaskRequest
    );
  }

  ExecuteValuationTask(
    executeValuationTaskRequest: ExecuteValuationTaskRequest
  ): Observable<ExecuteValuationTaskResponse> {
    return this.http.post<ExecuteValuationTaskResponse>(
      `${this.apiUrl}api/valuationruns`,
      executeValuationTaskRequest
    );
  }

  UpdateDealTerm(
    updateDealTermRequest: UpdateDealTermRequest
  ): Observable<DealTerm> {
    return this.http.put<DealTerm>(
      `${this.apiUrl}api/dealterms`,
      updateDealTermRequest
    );
  }

  UpdateGenerationUnit(
    updateGenerationUnitRequest: any
  ): Observable<DealTerm> {
    return this.http.put<any>(
      `${this.apiUrl}api/resppa/updategenerationunit`,
      updateGenerationUnitRequest
    );
  }

  UpdatePPADeal(
    updatePPADealRequest: any
  ): Observable<DealTerm> {
    return this.http.post<any>(
      `${this.apiUrl}api/resppa/dealObject`,
      updatePPADealRequest
    );
  }


  UpdateAsset(
    updateAssetRequest: any
  ): Observable<DealTerm> {
    return this.http.put<any>(
      `${this.apiUrl}api/resppa/updateassetobject`,
      updateAssetRequest
    );
  }

  UpdateMaLo(
    updateMaLoRequest: any
  ): Observable<DealTerm> {
    return this.http.put<any>(
      `${this.apiUrl}api/resppa/malo`,
      updateMaLoRequest
    );
  }

  UpdateTransaction(
    updateTransactionRequest: any
  ): Observable<DealTerm> {
    return this.http.put<any>(
      `${this.apiUrl}api/resppa/transaction`,
      updateTransactionRequest
    );
  }

  UpdateModelResult(
    updateModelResultRequest: UpdateModelResultRequest
  ): Observable<ModelResultDataObject> {
    return this.http.put<ModelResultDataObject>(
      `${this.apiUrl}api/timeseries/modelresult`,
      updateModelResultRequest
    );
  }

  UpdateValuationTask(
    updateValuationTaskRequest: UpdateValuationTaskRequest
  ): Observable<UpdateValuationTaskResponse> {
    return this.http.put<UpdateValuationTaskResponse>(
      `${this.apiUrl}api/valuationtasks`,
      updateValuationTaskRequest
    );
  }

  GetValuationRuns(
    dealTermId: string,
    valuationTaskId: string
  ): Observable<ValuationRun[]> {
    return this.http.get<ValuationRun[]>(
      `${this.apiUrl}api/valuationruns/${dealTermId}/${valuationTaskId}`
    );
  }

  GetGenerationProfiles(): Observable<GenerationProfile[]> {
    return this.http.get<GenerationProfile[]>(
      `${this.apiUrl}api/reference-data/generation-profiles`
    );
  }

  GetContractProfiles(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.apiUrl}api/reference-data/contract-profile-ts`
    );  
  }

  GetGenericCurves(): Observable<TimeSeriesTypes[]> {
    return this.http.get<TimeSeriesTypes[]>(
      `${this.apiUrl}api/reference-data/generic-curves`
    );
  }

  GetForwardCurves(): Observable<(ForwardCurve | ForwardCurveReference)[]> {
    return this.http.get<(ForwardCurve | ForwardCurveReference)[]>(
      `${this.apiUrl}api/reference-data/forward-curves`
    );
  }

  CreateHistoricDataset(
    createRequest: CreateHistoricDatasetRequest
  ): Observable<SimpleCommandResult> {
    return this.http.post<SimpleCommandResult>(
      `${this.apiUrl}api/historicdataset`,
      createRequest
    );
  }

  UpdateHistoricDataset(
    updateRequest: UpdateHistoricDatasetRequest
  ): Observable<SimpleCommandResult> {
    return this.http.put<SimpleCommandResult>(
      `${this.apiUrl}api/historicdataset`,
      updateRequest
    );
  }

  NameExists(
    dataObject: DataObjectTypeName,
    name: string
  ): Observable<boolean> {
    return this.http
      .get<{ value: boolean }>(
        `${this.apiUrl}api/dataobjects/name-exists/${dataObject}`,
        {
          params: {
            name,
          },
        }
      )
      .pipe(map((result) => result.value));
  }

  StorageLocationExists(storagelocation: string): Observable<boolean> {
    return this.http
      .get<{ value: boolean }>(
        `${this.apiUrl}api/historicdataset/storagelocation-exists`,
        {
          params: {
            storagelocation,
          },
        }
      )
      .pipe(map((result) => result.value));
  }

  UpsertForwardCurveReference(
    payload: UpsertForwardCurveReferenceRequest
  ): Observable<UpsertForwardCurveReferenceResponse> {
    return this.http.post<UpsertForwardCurveReferenceResponse>(
      `${this.apiUrl}api/forwardcurvereferences`,
      payload
    );
  }

  UpsertVolatilityReference(
    payload: UpsertVolatilityReferenceRequest
  ): Observable<UpsertVolatilityReferenceResponse> {
    return this.http.post<UpsertVolatilityReferenceResponse>(
      `${this.apiUrl}api/volatilityreferences`,
      payload
    );
  }

  StartExportToAnalyticalSandbox(
    items: ExportToAnalyticalSandboxModel[]
  ): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}api/analyticalsandbox/migrate`, {
      migrationRequests: items,
    });
  }

  GetApiInfo(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}`);
  }

  AddTimeSeriesRevision(
    payload: AddTimeSeriesRevisionRequest
  ): Observable<AddTimeSeriesRevisionResponse> {
    return this.http.post<AddTimeSeriesRevisionResponse>(
      `${this.apiUrl}api/timeseries/genericcurveobject-revision`,
      payload
    );
  }

  CreateTimeseriesRevisionBasedOnReference(
    payload: CreateTimeseriesRevisionBasedOnReferenceRequest
  ): Observable<SimpleCommandResult> {
    return this.http.post<SimpleCommandResult>(
      `${this.apiUrl}api/timeseries/genericcurveobject/queue-calibration-processing`,
      payload
    );
  }

  UploadProfileMatrixCsv(
    files: FormData
  ): Observable<UploadProfileMatrixCsvResponse> {
    return this.http.post<UploadProfileMatrixCsvResponse>(
      `${this.apiUrl}api/timeseries/read-profilematrix-timeseries-csv`,
      files
    );
  }

  CreateSyntheticCurveFromProfile(
    request: CreateSyntheticCurveFromProfileRequest
  ): Observable<SimpleCommandResult> {
    return this.http.post<SimpleCommandResult>(
      `${this.apiUrl}api/timeseries/genericcurveobject/queue-profilematrix-processing`,
      request
    );
  }

  UpdateTimeSeriesRevisionLabels(
    payload: UpdateTimeSeriesRevisionLabelsRequest
  ): Observable<UpdateTimeSeriesRevisionLabelsResponse> {
    return this.http.put<UpdateTimeSeriesRevisionLabelsResponse>(
      `${this.apiUrl}api/timeseries/genericcurveobject-revision/labels`,
      payload
    );
  }

  DeleteTimeSeriesRevision(
    curveId: string,
    revisionId: number,
    objectType: DataObjectTypeName
  ): Observable<SimpleCommandResult> {
    return this.http.delete<SimpleCommandResult>(
      `${this.apiUrl}api/timeseries/genericcurveobject-revision/${curveId}/${revisionId}/${objectType}`
    );
  }

  DeleteValuationRun(
    dealTermId: string,
    valuationTaskId: string,
    valuationRunIds: string[]
  ): Observable<SimpleCommandResult> {
    return this.http.post<SimpleCommandResult>(
      `${this.apiUrl}api/valuationruns/delete`,
      {
        dealTermId,
        valuationTaskId,
        valuationRunIds,
      }
    );
  }

  GetDataObject<T = DataObjectTypes>(
    pk: string,
    id: string,
    showLoading: boolean = true
  ): Observable<T> {
    var options = showLoading ? {} : { context: withSilentLoadContext() };
    return this.http.get<T>(
      `${this.apiUrl}api/dataobjects/${pk}/${id}`,
      options
    );
  }

  GetAllTSDataObjects(
    timeSeriesId: string,
    timeSeriesRevisionId: string
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}api/timeseries/${timeSeriesId}/${timeSeriesRevisionId}`
    );
  }

  GetKeyMetricsOfCurve(
    timeSeriesId: string,
    timeSeriesRevisionId: any,
    startRange: any,
    endRange: any
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}api/timeseries/matrix/${timeSeriesId}/${timeSeriesRevisionId}/${startRange}/${endRange}`
    );
  }

  GetEndurDealTS(
    timeSeriesId: string,
    timeSeriesRevisionId: string
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}api/timeseries/endur/${timeSeriesId}/${timeSeriesRevisionId}`
    );
  }

  GetEndurDataSeriesTS(
    timeSeriesId: string,
    timeSeriesRevisionId: string
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}api/timeseries/endurwithmonthname/${timeSeriesId}/${timeSeriesRevisionId}`
    );
  }


  SearchBackgroundJobs(
    qry: SearchBackgroundJobsQuery,
    showLoading: boolean = false
  ): Observable<SearchBackgroundJobsQueryResult> {
    var options = showLoading ? {} : { context: withSilentLoadContext() };
    return this.http.post<SearchBackgroundJobsQueryResult>(
      `${this.apiUrl}api/backgroundjobs/search`,
      qry,
      options
    );
  }

  ImportEscalatedForwardCurveRefAsOfDate(
    escalatedForwardCurveReferenceId: string,
    asOfDate: string
  ): Observable<void> {
    return this.http.post<any>(
      `${this.apiUrl}api/escalatedforwardcurvere/import`,
      { escalatedForwardCurveReferenceId, asOfDate }
    );
  }

  ImportHistoricDatasetGenerationProfile(
    historicDatasetId: string,
    renewableType: string,
    location: string
  ): Observable<SimpleCommandResult> {
    return this.http.post<any>(`${this.apiUrl}api/historicdataset/import`, {
      historicDatasetId,
      renewableType,
      location,
    });
  }

  StartImfPercentilesCalculations(
    request: StartImfPercentilesCalculationRequest
  ): Observable<SimpleCommandResult> {
    return this.http.post<SimpleCommandResult>(
      `${this.apiUrl}api/calculations/imf-and-percentiles`,
      request
    );
  }

  StartScheduleValuationRun(
    scheduleValuationJSON: any
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}api/schedule`,
      scheduleValuationJSON
    );
  }

  GetUserGeneratedData(data: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.apiUrl}api/reference-data/user-generated-data/${data}`
    );
  }

  getSettlementRelations(ppaDealId: any): Observable<any> {
    return this.http.get<string[]>(
      `${this.apiUrl}api/resppa/settlementRelation/${ppaDealId}`
    );
  }

  CreateSettlementRelation(
    createSettlementRelationRequest: any
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}api/resppa/settlementRelation`,
      createSettlementRelationRequest
    );
  }
}
