import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { AgGridModule } from 'ag-grid-angular';
import { DataObjectFiltersComponent } from './dataobject-filters.component';

@NgModule({
  declarations: [DataObjectFiltersComponent],
  exports: [DataObjectFiltersComponent],
  imports: [CommonModule, AgGridModule, ReactiveFormsModule, FormlyModule],
})
export class DataObjectFiltersComponentModule {}
