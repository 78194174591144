<ng-container
  *ngIf="notification$ | async as notification"
  [ngSwitch]="notification.type"
>
  <div class="m-4 w-full fixed z-[999999] top-0 right-0">
    <div class="relative w-96 mx-auto drop-shadow-xl">
      <app-notification-error
        *ngSwitchCase="'error'"
        [notification]="notification"
        (dismiss)="onDismiss()"
      ></app-notification-error>
      <app-notification-success
        *ngSwitchCase="'success'"
        [notification]="notification"
        (dismiss)="onDismiss()"
      ></app-notification-success>
      <app-notification-info
        *ngSwitchCase="'info'"
        [notification]="notification"
        (dismiss)="onDismiss()"
      ></app-notification-info>
    </div>
  </div>
</ng-container>
