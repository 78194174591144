import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { remoteDataLoaded } from "src/app/models/remoteData.model";
import { BackgroundJobUpdates, clearAll, closeUpdates, selectBackgroundJobUpdates, toggleUpdates } from "src/app/state/background-jobs.state";
import { ApiService } from "../../services/api.service";
import { DataObjectStore } from "../../services/dataObjectStore.service";
import { ReferenceDataStore } from "../../services/referenceData.store";

@Component({
    selector: 'app-logged-on-user',
    template: `
    <div>
        <div class="relative ml-3">
        <ng-container *ngIf="backgroundJobUpdates$ | async as updates">
            <div>
            <button (clickOutside)="close()" type="button" (click)="toggleBackgroundJobs()" 
                    class="max-w-xs bg-uniper rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-white" 
                    id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span class="relative inline-block h-8 w-8 rounded-full bg-gray-100">
                <svg class="h-full w-full text-gray-300 rounded-full" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
                <span *ngIf="updates.hasUnreadUpdates" class="absolute bottom-0 right-0 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white"></span>
                </span>
                <span class="ml-2 text-blue-200 hover:text-white">{{loggedInText}}</span>
            </button>
            </div>
            <div *ngIf="updates.isVisible" class="absolute right-0 z-10 mt-2 w-96 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                <div class="text-right"><a *ngIf="updates.jobs.length > 0" class=" cursor-pointer px-4 py-2 text-sm text-blue-400" (click)="clearAll()">Clear All Updates</a></div>
                <a *ngFor="let update of updates.jobs" class="flex px-4 py-2 text-sm hover:bg-gray-100" role="menuitem" tabindex="-1" id="user-menu-item-0">
                    <app-background-jobs-icon [bj]="update.job"></app-background-jobs-icon>
                    <div>
                        <span class="ml-2 text-gray-700" [class.font-medium]="update.notRead">{{update.job.objectMeta.name}}</span>
                        <span class="ml-2 text-gray-500">[<span timeago [date]="update.timestampReceived" [live]="live"></span>]</span>
                    </div>
                </a>
                <div *ngIf="updates.jobs.length === 0" class="px-4 py-2 text-sm text-gray-700">
                    <p>No Background Job Updates Available.</p>                    
                    <p class="mt-6 text-right"><a routerLink="background-jobs" class="m3 text-blue-400">View All Background Jobs</a><p>
                </div>
            </div>
        </ng-container>
        </div>
    </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoggedOnUserComponent {

    @Input() loggedInText: string | null = null;

    live = false;
    backgroundJobUpdates$: Observable<BackgroundJobUpdates> = this.store.select(selectBackgroundJobUpdates);

    constructor(
        private store: Store,
        private apiService: ApiService,
        private refDataStore: ReferenceDataStore,
        public dataObjstore: DataObjectStore
    ) {
        this.backgroundJobUpdates$.subscribe((res) => {
            if (res.jobs.length) {
                let calibrateCurveUpdate = res.jobs.find((item) => item.job.partitionKey == 'CalibrateGenericCurve')
                if (calibrateCurveUpdate?.job.jobStatus.status == 'Succeeded' && calibrateCurveUpdate.notRead) {
                    this.apiService.GetValuationRunPriceCurves().subscribe((res) => {
                        this.refDataStore.updateValuationRunPriceCurvesRemoteState(
                            remoteDataLoaded(res)
                        )
                    })
                }
                let itemUpdate = res.jobs.find((item) => item.job.partitionKey == 'CalculateImfAndPercentiles')
                if (itemUpdate?.job.jobStatus.status == 'Succeeded' && itemUpdate.notRead) {
                    this.dataObjstore.SearchDataObjects({});
                }
            }
        })
    }

    toggleBackgroundJobs() { this.store.dispatch(toggleUpdates()); }

    close() { this.store.dispatch(closeUpdates()); }

    clearAll() { this.store.dispatch(clearAll()); }
}
