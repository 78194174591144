import { FormlyForm } from "@ngx-formly/core"
import { ApiService } from "../services/api.service";
import { filter, switchMap, take, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { LocalStorageService } from "ngx-store";


export function TOOLTIPS_DATA(apiService?: ApiService): any {
  if (apiService) {
    apiService.GetTooltips().subscribe((res: any) => {
      if (res) {
        apiService.updateTooltipData(res.toolTipsData);
      }
    })
  } else {
    // Handle the case when ApiService is not provided
    console.log('ApiService is not provided');
    return of(null); // Return an empty observable or handle the error case accordingly
  }
}

export function PPA_DROPDOWN_OPTIONS(apiService: ApiService, localStorage: LocalStorageService): any {
	if (apiService && localStorage) {
		const data = localStorage.get(
			'ppa_dropdown_options'
		  );
		  if(!data){
			apiService.GetPPA_DROPDOWN_OPTIONS().subscribe((res: any) => {
				  localStorage.set('ppa_dropdown_options', res);
				  return data;
			  });
		  }
	} else {
	  // Handle the case when ApiService is not provided
	  console.log('ApiService is not provided');
	  return of(null); // Return an empty observable or handle the error case accordingly
	}
  }

export const ConverterOptions = [
	{
		"name" : "CSV DE",
		"columnSeparator" : ";",
		"decimalSeparator" : ",",
	},
	{
		"name" : "CSV EN",
		"columnSeparator" : ",",
		"decimalSeparator" : ".",
	},
	{
		"name" : "TSV DE",
		"columnSeparator" : "\t",
		"decimalSeparator" : ",",
	},
	{
		"name" : "TSV EN",
		"columnSeparator" : "\t",
		"decimalSeparator" : ".",
	}	
]

