import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { LoggedOnUser } from '../../models/loggedonUser.model';

@Injectable({
  providedIn: 'root',
})
export class UniperAuthService {
  constructor(private msal: MsalService) { }

  public get IsLoggedIn() {
    return this.msal.instance.getAllAccounts().length > 0;
  }

  public get LoggedOnUser(): LoggedOnUser | null {
    if (this.IsLoggedIn) {
      const account = this.msal.instance.getAllAccounts()[0];
      return new LoggedOnUser(account.name ?? 'not specfied', account.username, account?.idTokenClaims?.roles);
    } else return null;
  }

  public get hasAdminAccess(): any | null {
    if (this.IsLoggedIn) {
      const account: any = this.msal.instance.getAllAccounts()[0];
      if (account.idTokenClaims && account.idTokenClaims.roles) {
        const isManager = account.idTokenClaims.roles.includes('Manager');
        return isManager;
      } else {
        return null
      }
    } else return null;
  }

  public get hasReaderAccess(): any | null {
    if (this.IsLoggedIn) {
      const account: any = this.msal.instance.getAllAccounts()[0];
      return account.idTokenClaims.roles.includes['Reader'];
    } else return null;
  }
}
