import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { DataManagementHeaderComponent } from './data-management-header.component';
import { ActionButtonComponentModule } from '../action-button/action-button.component.module';
import { UpsertObjectHeaderComponent } from './upsert-object-header.component';
import { ValuationCockpitHeaderComponent } from './valuation-cockpit-header.component';
import { ValuationResultHeaderComponent } from './valuation-result-header.component';
import { BackgroundJobsHeaderComponent } from './background-jobs-header.component';
import { RouterModule } from '@angular/router';
import { GraphBasketHeaderComponent } from './graph-basket-header.component';
import { DashboardWidgetComponentModule } from 'src/app/pages/dashboard/components/dashboard-widgets/dashboard-widget.module';
import { PPAInventoryHeaderComponent } from './ppa-inventory-header.component';
import { UpsertPPAObjectHeaderComponent } from './upsert-ppa-object-header.component';

@NgModule({
  declarations: [
    HeaderComponent,
    // DashboardWidgetHeaderComponent,
    DataManagementHeaderComponent,
    PPAInventoryHeaderComponent,
    UpsertObjectHeaderComponent,
    UpsertPPAObjectHeaderComponent,
    ValuationCockpitHeaderComponent,
    ValuationResultHeaderComponent,
    BackgroundJobsHeaderComponent,
    GraphBasketHeaderComponent,
  ],
  exports: [HeaderComponent],
  imports: [CommonModule, ActionButtonComponentModule, RouterModule, DashboardWidgetComponentModule],
})
export class HeaderComponentModule {}
