import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  currentRoute: string = '';
  constructor(private router: Router) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  isActive(...routes: string[]): boolean {
    // dashboard data-management
    return routes.find((route) => route === '/dashboard') &&
      this.currentRoute === '/'
      ? true
      : !!routes.find((route) => this.currentRoute.startsWith(route));
  }
}
