import { filter, map, OperatorFunction, pipe } from 'rxjs';
import { getValue } from './utils';

export function assertNotNullOrUndefined<T>(): OperatorFunction<
  T | null | undefined,
  NonNullable<T>
> {
  return pipe(map((value) => getValue(value)));
}

export function filterNullOrUndefined<T>(): OperatorFunction<
  T | null | undefined,
  NonNullable<T>
> {
  return pipe(
    filter((value) => value !== null && value !== undefined),
    assertNotNullOrUndefined()
  );
}
