import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CosmosDocumentRef } from './cosmosDocumentRef.model';
import { DataObject, DataObjectTypeName } from './dataObject.model';
import { ModelCalibration } from './modelCalibration.model';
import { SelectOption } from './select.model';
import { TimeseriesPreviewDetails } from './timeseries.model';
import {
  ValuationRunPriceCurve,
  ValuationRunPriceCurveRevision,
} from './valuationRunPriceCurve.model';
import { VolatilityReference } from './volatilityreference.model';
import { WrappedTags } from './tags.model';

export interface ValuationTask extends DataObject {
  objectBody: {
    dealTermId: string;
    pricingIndex: PricingIndex;
    volatilityIndex: VolatilityIndex;
    modelConfiguration: ModelConfiguration;
    valuationRuns: number;
    hedgeConfiguration?: HedgeConfiguration | null;
  };
}

export interface HedgeConfiguration {
  buySell: string;
  hedges: HedgeDetails[];
}

export interface HedgeDetails {
  profileVolume: number;
  initialVolume: number;
  strike: number;
}

export interface PricingIndex {
  curveRef: CosmosDocumentRef;
  defaultRevision: string | null;
}

export interface VolatilityIndex {
  curveReferenceId: string;
}

export interface ModelConfiguration {
  modelCalibrationId: string;
  modelCalibrationIsValid: boolean | null;
  modelId: string | null;
  regressionType: string;
  defaultCalculationSpecs: string[];
  defaultRounding: number | null;
  defaultSeed: number | null;
  defaultNumSims: number | null;
}

export interface ManageValuationTaskForm {
  dealTermId: string;
  name: string | null;
  valuationTaskId: string | null;
  description?: string | null;
  tags?: string[] | null;
  forwardPrice: SelectOption<ValuationRunPriceCurve> | null;
  defaultRevision?: SelectOption<ValuationRunPriceCurveRevision> | null;
  volatalities: SelectOption<VolatilityReference> | null;
  modelCalibration: SelectOption<ModelCalibration> | null;
  defaultCalculationSpecs: SelectOption[] | null;
  defaultRounding?: number | null;
  defaultSeed?: number | null;
  defaultNumberOfSimulations?: number | null;
  valuationRunExists: boolean;
  timeseriesPreview?: TimeseriesPreviewDetails | null;
  hedgeConfiguration: HedgeConfigurationForm;
}

export interface ScheduleValuationRunForm {
  automatedExecution: SelectOption;
  deactivationDate: Date | null;
  offsetAsOfDate: number;
}

export interface HedgeConfigurationForm {
  maxAllowedHedgeConfigurations: number[];
  dealTermInstalledCapacityInMw: number;
  buySell: string | null;
  hedgeProfilesInMW?: WrappedTags<number> | null;
  initialHedges: InitialHedgeConfigurationForm[];
}

export interface InitialHedgeConfigurationForm {
  volumeInMW: number;
  price: number;
  hedgeProfileInMW: number;
  hedgeProfileYear: number;
}

export interface ManageValuationTaskViewModel {
  fields: FormlyFieldConfig[];
  model: Partial<ManageValuationTaskForm>;
  form: FormGroup;
}

export interface ManageValuationTaskState {
  formlyFieldConfig: FormlyFieldConfig[] | null;
  model: Partial<ManageValuationTaskForm> | null;
  formGroup: FormGroup;
}

export interface CreateValuationTaskRequest {
  dealTermId: string;
  name: string;
  description: string | null;
  tags: string[];
  forwardCurveIdentifier: {
    pk: string;
    id: string;
  };
  defaultForwardCurveRevisionId: string | null;
  volatilityReferenceId: string;
  modelCalibrationId: string;
  regressionType: string;
  defaultCalculationSpecs: string[];
  defaultRounding: number | null;
  defaultSeed: number | null;
  defaultNumSims: number | null;
  hedgeConfiguration?: HedgeConfiguration | null;
}

export interface CreateValuationTaskResponse {
  isSuccess: boolean;
  failures: string[];
  csvFailures: string;
  value: ValuationTask;
}

export interface UpdateValuationTaskRequest extends CreateValuationTaskRequest {
  valuationTaskId: string;
}

export interface UpdateValuationTaskResponse {
  isSuccess: boolean;
  failures: string[];
  csvFailures: string;
  value: ValuationTask;
}

export function isValuationTaskObject(value: unknown): value is ValuationTask {
  return (
    (value as ValuationTask)?.objectType === DataObjectTypeName.ValuationTask
  );
}
