import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DataObject, DataObjectTypeName } from './dataObject.model';
import { KeyValuePair } from './keyValuePair.model';
import { SelectOption } from './select.model';
import {
  TimeseriesGranularity,
  TimeseriesPreviewDetails,
} from './timeseries.model';
import {
  ValuationRunPriceCurve,
  ValuationRunPriceCurveRevision,
} from './valuationRunPriceCurve.model';

export interface ValuationRun extends DataObject {
  objectBody: {
    friendlyId: string;
    valuationTaskIdentifier: {
      dealTermId: string;
      valuationTaskId: string;
    };
    valuationDate: string;
    asOfDate: string;
    pricingIndexRevisionId: string | null;
    simulationParameter: {
      calculationSpecs: string[];
      rounding: number;
      seed: number;
      numSims: number;
    };
    runStatus: {
      fmpDagRunId: string;
      startedUtc: string;
      status: ValuationRunStatusValues;
      completedUtc: string | null;
      errorDetails: string | null;
    };
    modelResults: {
      scalarResults: KeyValuePair<ModelResultScalarValue>[];
      timeSeriesResults: TimeseriesIdentifier[];
      yearSummaryResults: ValuationYearSummaryResult[];
    };
  };
}

export interface ValuationYearSummaryResult {
  interval: string;
  npv: number;
  volume: number;
  avgPrice: number;
}

export enum ValuationRunStatusValues {
  Processing = 'processing',
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export interface ModelResultScalarValue {
  value: number;
  currency: string | null;
}

export interface TimeseriesIdentifier {
  name: string;
  id: string;
  revisionId: number;
  timezone: string;
  uom: string;
  granularity: string;
}

export interface ExecuteValuationTaskForm {
  valuationTaskId: string;
  dealTermId: string;
  valuationDate: Date | null;
  asOfDate: Date | null;
  forwardPrice: SelectOption<ValuationRunPriceCurve> | null;
  revision?: SelectOption<ValuationRunPriceCurveRevision> | null;
  rounding: number | null;
  seed: number | null;
  numSims: number | null;
  calculationSpecs: SelectOption[] | null;
  timeseriesPreview?: TimeseriesPreviewDetails | null;
  isScenarioDataRequested: boolean;
}

export interface ExecuteValuationTaskViewModel {
  fields: FormlyFieldConfig[];
  model: Partial<ExecuteValuationTaskForm>;
  form: FormGroup;
}

export interface ExecuteValuationTaskState {
  formlyFieldConfig: FormlyFieldConfig[] | null;
  model: Partial<ExecuteValuationTaskForm> | null;
  formGroup: FormGroup;
}

export interface ExecuteValuationTaskRequest {
  valuationTaskIdentifier: {
    dealTermId: string;
    valuationTaskId: string;
  };
  valuationDate: string;
  asOfDate: string;
  pricingIndexRevisionId: string | null;
  calculationSpecs: string[];
  rounding: number;
  seed: number;
  numSims: number;
  isScenarioDataRequested: boolean;
}

export interface ExecuteValuationTaskResponse {
  isSuccess: boolean;
  failures: string[];
  csvFailures: string;
  value: ValuationRun;
}

export function isValuationRunObject(value: unknown): value is ValuationRun {
  return (
    (value as ValuationRun)?.objectType === DataObjectTypeName.ValuationRun
  );
}
