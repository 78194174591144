import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';
import { JsonModalComponent } from './json-modal.component';

@NgModule({
  declarations: [JsonModalComponent],
  exports: [JsonModalComponent],
  imports: [
    ClipboardModule,
    CommonModule
  ],
})
export class JsonModalComponentModule {}
