import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  ContractProfile,
  ManageContractProfileTimeSeriesForm,
} from './contractProfile.model';
import {
  DataObject,
  DataObjectTypeName,
  DataObjectTypes,
} from './dataObject.model';
import {
  ForwardCurve,
  ManageForwardCurveTimeSeriesForm,
} from './forwardcurve.model';
import {
  GenerationProfile,
  ManageGenerationProfileTimeSeriesForm,
} from './generationProfile.model';
import { Granularity } from './granularity.enum';
import { SelectOption } from './select.model';
import { ManageSpotPricesTimeSeriesForm, SpotPrices } from './spotPrices.model';
import { TimeSeriesRevision } from './timeSeriesRevision.model';

export interface TimeSeriesDataObject extends DataObject {
  timeSeriesMeta: TimeSeriesMetaData;
}

export interface TimeSeriesMetaData {
  granularity: string;
  timezone: string;
  uom: string;
  revisions: TimeSeriesRevision[];
}

export const TIME_SERIES_DATA_OBJECTS = [
  [DataObjectTypeName.ForwardCurve, 'Forward Curve'],
  [DataObjectTypeName.SpotPrices, 'Spot Prices'],
  [DataObjectTypeName.ContractProfile, 'Contract Profile'],
  [DataObjectTypeName.GenerationProfile, 'Generation Profile'],
] as Array<[DataObjectTypeName, string]>;

export type TimeSeriesTypes =
  | ForwardCurve
  | GenerationProfile
  | ContractProfile
  | SpotPrices;

export function isTimeSeriesObjectType(
  dataObject?: DataObjectTypes | null
): dataObject is TimeSeriesTypes {
  return !!TIME_SERIES_DATA_OBJECTS.map(([value]) => value).find(
    (objectTypeName) =>
      (dataObject as DataObject)?.objectType === objectTypeName
  );
}

export function assertTimeSeriesType(
  value: DataObjectTypes
): asserts value is TimeSeriesTypes {
  if (!isTimeSeriesObjectType(value)) {
    throw new Error(`value ${value} is not TimeSeriesType`);
  }
}

export function getTimeSeriesDisplayName(
  objectType?: DataObjectTypeName | null
): string | null {
  return (
    TIME_SERIES_DATA_OBJECTS.find(
      ([objectTypeName]) => objectType === objectTypeName
    )?.[1] || null
  );
}

export interface ManageTimeSeriesForm {
  timeSeriesId: string | null;
  objectType: SelectOption | null;
  timeSeriesDetails:
    | Partial<ManageForwardCurveTimeSeriesForm>
    | Partial<ManageGenerationProfileTimeSeriesForm>
    | Partial<ManageContractProfileTimeSeriesForm>
    | Partial<ManageSpotPricesTimeSeriesForm>
    | null;
}

export interface ManageTimeSeriesDetailsViewModel {
  fields: FormlyFieldConfig[];
  model: Partial<ManageTimeSeriesForm>;
  form: FormGroup;
  objectType: DataObjectTypeName | null;
  editMode: boolean;
}

export interface ManageTimeSeriesDetailsState {
  formlyFieldConfig: FormlyFieldConfig[] | null;
  model: Partial<ManageTimeSeriesForm> | null;
  formGroup: FormGroup;
  editMode: boolean;
}

export interface ManageTimeSeriesState {
  dataObject: DataObjectTypes | null;
}

export interface ManageTimeSeriesViewModel {
  dataObject: DataObjectTypes;
  editMode: boolean;
}

export interface CreateTimeSeriesRequest {
  objectType: DataObjectTypeName;
  name: string;
  tags: string[];
  description: string | null;
  granularity: Granularity;
  timeZone: string;
  datasource: string | null;
  uoM: string;
  market: string | null;
  location: string | null;
  asOfDate: string | null;
  commodity: string;
  revisionLabels: string[];
  csvStorageAccountFilePath?: string | null;
}

export interface UpdateTimeSeriesRequest {
  id: string;
  name: string;
  tags: string[];
  description: string | null;
  datasource: string | null;
  asOfDate: string | null;
  objectType: DataObjectTypeName;
}
