// selected-components.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardWidgetService {
    private selectedComponentsSubject = new BehaviorSubject<string[]>(['Valuation Run Results', 'Background Jobs']); // Initial values here
    selectedComponents$: Observable<string[]> = this.selectedComponentsSubject.asObservable();
  
    updateSelectedComponents(components: string[]): void {
      this.selectedComponentsSubject.next(components);
    }
}
