import { CosmosDocumentRef } from './cosmosDocumentRef.model';
import { DataObject } from './dataObject.model';
import { TriggerModelCalibrationRequest } from './modelCalibration.model';
import { ValuationRunIdentifier } from './valuationRunIdentifier.model';

export interface BackgroundJobDataObject extends DataObject {
  jobStatus: BackgroundJobStatus;
  objectBody: any; // should be overridden in derived classes
}

export interface BackgroundJobStatus {
  status: JobStatusEnum;
  startedUtc: string;
  completedUtc: string;
  errorText: string | null;
  errorDetails: string | null;
}

export enum JobStatusEnum {
  Queued = 'Queued',
  Processing = 'Processing',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
}

export enum BackgroundJobObjectType {
  FmpModelValuationRun = 'FmpValuationModelRun',
  FmpModelCalibrationRun = 'FmpModelCalibrationRun',
  TimeSeriesDataAnalyticalSandboxMigration = 'TimeSeriesDataAnalyticalSandboxMigration',
  TimeSeriesMetaDataAnalyticalSandboxMigration = 'TimeSeriesMetaDataAnalyticalSandboxMigration',
  ForwardCurveCsvUpload = 'ForwardCurveCsvUpload',
  GenericCurveCsvUpload = 'GenericCurveCsvUpload',
  TimeSeriesAnalyticalSandboxDataPurge = 'TimeSeriesAnalyticalSandboxDataPurge',
  ImportEscalatedForwardCurveReference = 'ImportEscalatedForwardCurveRefAsOfDate',
  ProcessEscalatedForwardCurvesPythonScanTimerTrigger = 'ProcessEscalatedForwardCurvesPythonScanTimerTrigger',
  CalculateImfAndPercentiles = "CalculateImfAndPercentiles",
  ImportHistoricDatasetGenerationProfile = "ImportHistoricDatasetGenerationProfile",
  SynchronizeHistoricDatasetToBlobStorage = "SynchronizeHistoricDatasetToBlobStorage",
  CalibrateGenericCurve = "CalibrateGenericCurve",
  MatrixProfileGenericCurve = "MatrixProfileGenericCurve",
}

export type BackgroundJobTypes =
  | BackgroundJobDataObject
  | TimeSeriesMetaDataAnalyticalSandboxMigrationBackgroundJob
  | ValuationRunBackgroundJob
  | ForwardCurveCsvUploadBackgroundJob
  | TimeSeriesDataAnalyticalSandboxMigrationBackgroundJob
  | PurgeAnalyticalSandboxTimeSeriesDataBackgroundJob
  | ImportEscalatedForwardCurveReferenceBackgroundJob
  | ProcessEscalatedForwardCurvePythonScanTimerTriggerBackgroundJob
  | ModelCalibrationRunBackgroundJob;

export interface TimeSeriesMetaDataAnalyticalSandboxMigrationBackgroundJob
  extends BackgroundJobDataObject {
  objectBody: TimeSeriesMetaDataAnalyticalSandboxMigrationObjectBody;
}

export interface TimeSeriesMetaDataAnalyticalSandboxMigrationObjectBody {
  correlationId: string;
}

export interface TimeSeriesDataAnalyticalSandboxMigrationBackgroundJob
  extends BackgroundJobDataObject {
  objectBody: TimeSeriesDataAnalyticalSandboxMigrationObjectBody;
}

export interface TimeSeriesDataAnalyticalSandboxMigrationObjectBody {
  id: string;
  partitionKey: string;
  correlationId: string;
  tsObjectId: string;
  tsObjectName: string;
  tsVersion: number;
  labelList: string | null;
  migrationStatus: string;
  deleteAfter: string;
  createdBy: string | null;
  createdTs: string;
  tsCreatedBy: string | null;
  tsCreatedTs: string;
  tsObjectType: string;
  tsUom: string | null;
  tsGranularity: string;
  tsTimezone: string;
  errorReason: string;
  parentId: string | null;
  parentPartitionKey: string | null;
}

export interface PurgeAnalyticalSandboxTimeSeriesDataBackgroundJob
  extends BackgroundJobDataObject {
  objectBody: PurgeAnalyticalSandboxTimeSeriesDataObjectBody;
}

export interface PurgeAnalyticalSandboxTimeSeriesDataObjectBody {}

export interface ValuationRunBackgroundJob extends BackgroundJobDataObject {
  objectBody: ValuationRunBackgroundJobObjectBody;
}

export interface ModelCalibrationRunBackgroundJob
  extends BackgroundJobDataObject {
  objectBody: ModelCalibrationRunBackgroundJobObjectBody;
}

export interface ValuationRunBackgroundJobObjectBody {
  fmpDagRunId: string;
  modelRequest: string;
  modelRequestFile: any;
  valuationRunIdentifier: ValuationRunIdentifier;
}

export interface ModelCalibrationRunBackgroundJobObjectBody {
  fmpDagRunId: string;
  calibrationRequest: TriggerModelCalibrationRequest;
  triggerRequest: any;
}

export interface ForwardCurveCsvUploadBackgroundJob
  extends BackgroundJobDataObject {
  objectBody: ForwardCurveCsvUploadObjectBody;
}

export interface ForwardCurveCsvUploadObjectBody {
  forwardCurveId: string;
  revisionId: number;
  csvStorageAccountFilePath: string;
}

export interface ImportEscalatedForwardCurveReferenceBackgroundJob
  extends BackgroundJobDataObject {
  objectBody: ImportEscalatedForwardCurveReferenceObjectBody;
}

export interface ImportEscalatedForwardCurveReferenceObjectBody {
  escalatedForwardCurveReferenceId: string;
  asOfDate: string;
}

export interface ProcessEscalatedForwardCurvePythonScanTimerTriggerBackgroundJob
  extends BackgroundJobDataObject {
  objectBody: ProcessEscalatedForwardCurvePythonScanTimerTriggerObjectBody;
}

export interface ProcessEscalatedForwardCurvePythonScanTimerTriggerObjectBody {
  scanTs: string;
  escalatedForwardCurveFilesCount: number;
}

export interface CalculateImfAndPercentilesBackgroundJob extends BackgroundJobDataObject {
  objectBody: CalculateImfAndPercentilesBackgroundJobObjectBody;
}

export interface CalculateImfAndPercentilesBackgroundJobObjectBody {
  fmpDagRunId: string;
  calculateImfAndPercentilesCommand: CalculateImfAndPercentilesCommand;
}

export interface CalculateImfAndPercentilesCommand {
  valuationRunResultsRef: CosmosDocumentRef;
  percentiles: number[];
  imfMonthly: boolean;
  imfYearly: boolean;
  percentileMonthly: string[];
  percentileYearly: string[];
}