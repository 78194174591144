import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataObject, DataObjectTypes } from '../../models/dataObject.model';
import { DealTerm, isDealTermObject } from '../../models/dealTerm.model';
import {
  FilterFieldModel,
  SearchDataObjectModel,
} from '../../models/searchDataObjectResult.model';
import {
  isValuationTaskObject,
  ValuationTask,
} from '../../models/valuationTask.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DataObjectStore {
  private filterFields: any | null;
  private dataInternal$: BehaviorSubject<DataObject[]> = new BehaviorSubject<
    DataObject[]
  >([]);
  private filterFieldsInternal$: BehaviorSubject<FormlyFieldConfig[]> =
    new BehaviorSubject<FormlyFieldConfig[]>([]);
  private selectedDataObjectInternal$: BehaviorSubject<DataObjectTypes | null> =
    new BehaviorSubject<DataObjectTypes | null>(null);
  private selectedDealTermInternal$: BehaviorSubject<DealTerm | null> =
    new BehaviorSubject<DealTerm | null>(null);
  private selectedValuationTaskInternal$: BehaviorSubject<ValuationTask | null> =
    new BehaviorSubject<ValuationTask | null>(null);

  constructor(private api: ApiService) {}

  get Data$(): Observable<DataObject[]> {
    return this.dataInternal$;
  }
  get FilterFields$(): Observable<FormlyFieldConfig[]> {
    return this.filterFieldsInternal$;
  }
  get SelectedDataObject$(): Observable<DataObjectTypes | null> {
    return this.selectedDataObjectInternal$;
  }
  get SelectedDealTerm$(): Observable<DealTerm | null> {
    return this.selectedDealTermInternal$;
  }
  get SelectedValuationTask$(): Observable<ValuationTask | null> {
    return this.selectedValuationTaskInternal$;
  }

  get CurrentSelectedValuationTask(): ValuationTask | null {
    return this.selectedValuationTaskInternal$.getValue();
  }

  get CurrentSelectedDataObject(): DataObjectTypes | null {
    return this.selectedDataObjectInternal$.getValue();
  }

  SearchDataObjects(filterFields: any, isDashboardView?: any, dashboardFilters?: any) {
    this.filterFields = filterFields;
    this.api
      .SearchDataObjects(filterFields)
      .subscribe((result: SearchDataObjectModel) => {
        if (isDashboardView && result.data && result.data.length) {
          result.data = result.data.filter((item: any) => {
            return item.rmvdMeta.owner == dashboardFilters?.kid
          });
        }
        this.dataInternal$.next(result.data);
        this.filterFieldsInternal$.next(
          this.mapFilterFieldsToFormFieldConfig(result.filterFields)
        );
      });
  }

  Refresh() {
    this.SearchDataObjects(this.filterFields);
  }

  SetSelectedDataObject(item: DataObjectTypes) {
    if (isDealTermObject(item)) {
      this.selectedDealTermInternal$.next(item);
    } else if (isValuationTaskObject(item)) {
      this.selectedValuationTaskInternal$.next(item);
    }
    this.selectedDataObjectInternal$.next(item);
  }

  ClearSelectedDataObject() {
    this.selectedDataObjectInternal$.next(null);
    this.selectedDealTermInternal$.next(null);
    this.selectedValuationTaskInternal$.next(null);
  }

  mapFilterFieldsToFormFieldConfig(
    filterFields: FilterFieldModel[]
  ): FormlyFieldConfig[] {
    var mapped = filterFields.map((ff) => ({
      key: ff.fieldName,
      type: ff.fieldControlType,
      templateOptions: {
        type: ff.fieldControlType === 'input' ? 'text' : undefined,
        bindLabel: 'label',
        label: ff.label,
        options: ff.filterItems,
        bindId: ff.filterItemBindId,
        bindValue: ff.filterItemBindValue,
      },
    }));
    return mapped;
  }
}
