export function getBrowserCultureLang(window: Window): string {
  const navigator = window.navigator;
  let browserCultureLang = navigator.languages ? navigator.languages[0] : null;
  browserCultureLang =
    browserCultureLang ||
    navigator.language ||
    (navigator as any).browserLanguage ||
    (navigator as any).userLanguage;

  return browserCultureLang || 'en-US';
}
