import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { CalculateImfPercentilesModalComponentModule } from 'src/app/shared/components/calculate-imf-percentiles-modal/calculate-imf-percentiles-modal..component.module';
import { JsonModalComponentModule } from 'src/app/shared/components/json-modal/json-modal.component.module';
import { AggridObjecttypeTooltipComponentModule } from '../aggrid-objecttype-tooltip/aggrid-objecttype-tooltip.component.module';
import { DataObjectsDocumentsComponent } from './dataobjects-documents.component';

@NgModule({
  declarations: [DataObjectsDocumentsComponent],
  exports: [DataObjectsDocumentsComponent],
  imports: [
    CommonModule,
    AgGridModule,
    ReactiveFormsModule,
    AggridObjecttypeTooltipComponentModule,
    CalculateImfPercentilesModalComponentModule,
    JsonModalComponentModule
  ],
})
export class DataObjectsDocumentsComponentModule {}
