import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { LocalStorageService } from 'ngx-store';
import { Observable, map, of, tap } from 'rxjs';
import { Timeseries } from '../../models/timeseries.model';

@Injectable({
  providedIn: 'root',
})
export class CachedPreviewTimeseriesService {
  constructor(
    private api: ApiService,
    private localStorage: LocalStorageService
  ) {}

  getPreviewTimeseries(
    timeSeriesId: string,
    timeSeriesRevisionId: number
  ): Observable<Timeseries> {
    const cachedPreviewData = this.getCachedPreviewData(
      timeSeriesId,
      timeSeriesRevisionId
    );
    if (cachedPreviewData) {
      return of(cachedPreviewData);
    }
    return this.getTimeseriesAndUpdateCache(timeSeriesId, timeSeriesRevisionId);
  }

  getTimeseriesMetrics(
    timeSeriesId: string,
    timeSeriesRevisionId: number,
    startRange: any,
    endRange: any
  ): any {
    const cachedPreviewData = this.getCachedMetricsData(
      timeSeriesId,
      timeSeriesRevisionId
    );
    if (cachedPreviewData) {
      return cachedPreviewData;
    }
    return this.getMetricsAndUpdateCache(timeSeriesId, timeSeriesRevisionId, startRange, endRange);
  }

  getAllimeseries(
    timeSeriesId: string,
    timeSeriesRevisionId: number
  ): Observable<Timeseries> {
    const cachedPreviewData = this.getAllCachedPreviewData(
      timeSeriesId,
      timeSeriesRevisionId
    );
    if (cachedPreviewData) {
      return of(cachedPreviewData);
    }
    return this.getAllTimeseriesAndUpdateCache(timeSeriesId, timeSeriesRevisionId);
  }

  private getTimeseriesPreiviewKey(
    timeSeriesId: string,
    timeSeriesRevisionId: number
  ) {
    return `${timeSeriesId}_${timeSeriesRevisionId}_preview`;
  }

  private getTimeseriesMetricsKey(
    timeSeriesId: string,
    timeSeriesRevisionId: number
  ) {
    return `${timeSeriesId}_${timeSeriesRevisionId}_metrics`;
  }

  private getAllTimeseriesPreiviewKey(
    timeSeriesId: string,
    timeSeriesRevisionId: number
  ) {
    return `${timeSeriesId}_${timeSeriesRevisionId}_all`;
  }

  private getCachedPreviewData(
    timeSeriesId: string,
    timeSeriesRevisionId: number
  ): Timeseries | null {
    const data = this.localStorage.get(
      this.getTimeseriesPreiviewKey(timeSeriesId, timeSeriesRevisionId)
    )?.data;

    if (data) {
      this.localStorage.set(
        this.getTimeseriesPreiviewKey(timeSeriesId, timeSeriesRevisionId),
        {
          data,
          lastAccess: Date.now(),
        }
      );
    }
    return data;
  }

  private getCachedMetricsData(
    timeSeriesId: string,
    timeSeriesRevisionId: number
  ): Timeseries | null {
    const data = this.localStorage.get(
      this.getTimeseriesMetricsKey(timeSeriesId, timeSeriesRevisionId)
    );
    if (data) {
      this.localStorage.set(
        this.getTimeseriesMetricsKey(timeSeriesId, timeSeriesRevisionId),
        {
          ...data,
          lastAccess: Date.now(),
        }
      );
    }
    return data;
  }

  private getAllCachedPreviewData(
    timeSeriesId: string,
    timeSeriesRevisionId: number
  ): Timeseries | null {
    const data = this.localStorage.get(
      this.getAllTimeseriesPreiviewKey(timeSeriesId, timeSeriesRevisionId)
    )?.data;

    if (data) {
      this.localStorage.set(
        this.getAllTimeseriesPreiviewKey(timeSeriesId, timeSeriesRevisionId),
        {
          data: data.flat(),
          lastAccess: Date.now(),
        }
      );
    }
    return (data && data.length) ? data.flat() : data;
  }


  private getTimeseriesAndUpdateCache(
    timeSeriesId: string,
    timeSeriesRevisionId: number
  ): Observable<Timeseries> {
    return this.api
      .GetTimeSeriesPreview(timeSeriesId, timeSeriesRevisionId)
      .pipe(
        tap((data: Timeseries) =>
          this.localStorage.set(
            this.getTimeseriesPreiviewKey(timeSeriesId, timeSeriesRevisionId),
            {
              data,
              lastAccess: Date.now(),
            }
          )
        )
      );
  }


  private getMetricsAndUpdateCache(
    timeSeriesId: string,
    timeSeriesRevisionId: number,
    startRange: any,
    endRange: any
  ): any {
     this.api
      .GetKeyMetricsOfCurve(timeSeriesId, timeSeriesRevisionId, startRange, endRange)
      .subscribe(res => {
        this.localStorage.set(
          this.getTimeseriesMetricsKey(timeSeriesId, timeSeriesRevisionId),
          {
            ...res,
            lastAccess: Date.now(),
          }
        )
      })
  }


  private getAllTimeseriesAndUpdateCache(
    timeSeriesId: string,
    timeSeriesRevisionId: any
  ): Observable<Timeseries> {
    return this.api.GetAllTSDataObjects(timeSeriesId, timeSeriesRevisionId).pipe(
      map((data: Timeseries) => {
          return data.flat();
      }),
      tap((data: Timeseries) => {
          // Store the flattened data in local storage
          this.localStorage.set(
              this.getAllTimeseriesPreiviewKey(timeSeriesId, timeSeriesRevisionId),
              {
                  data: data.flat(),
                  lastAccess: Date.now(),
              }
          );
      })
  );
  }
}
