import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SILENT_LOAD_CONTEXT } from '../http-context-tokens/silent-load.context';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (req.context.get(SILENT_LOAD_CONTEXT)) {
      return next.handle(req);
    }
    this.loadingService.start();
    return next.handle(req).pipe(finalize(() => this.loadingService.stop()));
  }
}
