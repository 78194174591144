import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import {
  AddButtonConfig,
  RemoveButtonConfig,
} from 'src/app/models/repeatFormlyField.model';

@Component({
  selector: 'formly-field-repeat',
  template: `
    <div
      *ngFor="
        let field of field.fieldGroup as items;
        let i = index;
        let last = last
      "
      class="flex flex-col rounded-lg border-gray-300 justify-start border m-3 items-start"
    >
      <button
        type="button"
        *ngIf="showRemoveButton(last)"
        class="text-gray-400 hover:text-gray-500 self-end pr-2 pt-2"
        (click)="removeRow(i)"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
          />
        </svg>
      </button>
      <div class="flex flex-row w-full">
        <formly-field [field]="field" class="grow"></formly-field>
      </div>
    </div>
    <div class="m-3">
      <button
        class="primary-btn mr-2"
        type="button"
        (click)="addRow(i)"
        *ngFor="let addButtonConfig of addButtons; let i = index"
      >
        {{ addButtonConfig.text }}
      </button>
    </div>
  `,
})
export class RepeatFormlyField extends FieldArrayType {
  get addButtons(): AddButtonConfig[] {
    if (this.to['addText']) {
      return [{ text: this.to['addText'] }];
    }

    if (this.to['addButtons']) {
      return this.to['addButtons'] as AddButtonConfig[];
    }

    return [];
  }

  get removeButtonConfig(): RemoveButtonConfig | undefined {
    return this.to['removeButtonConfig'] as RemoveButtonConfig | undefined;
  }

  showRemoveButton(last: boolean): boolean {
    if (
      this.removeButtonConfig?.hideRemoveForSingleItem &&
      this.field.fieldGroup?.length === 1
    ) {
      return false;
    }

    if (this.removeButtonConfig?.showRemoveForLastItemOnly && !last) {
      return false;
    }

    return true;
  }

  addRow(index: number) {
    this.addButtons[index]?.preAddCallback?.(this.model, this.field);
    this.add();
    this.addButtons[index]?.postAddCallback?.(this.model, this.field);
  }

  removeRow(index: number) {
    this.removeButtonConfig?.preRemoveCallback?.(this.model, this.field, index);
    this.remove(index);
    this.removeButtonConfig?.postRemoveCallback?.(this.model, this.field);
  }
}

@NgModule({
  declarations: [RepeatFormlyField],
  exports: [RepeatFormlyField],
  imports: [CommonModule, FormlyModule],
})
export class RepeatFormlyFieldModule {}
