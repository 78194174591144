import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'formly-field-select',
  template: `
    <ng-select
      [formControl]="formControl"
      [bindLabel]="to['bindValue']"
      [formlyAttributes]="field"
      [class.input-error]="showError"
      [placeholder]="to.placeholder ?? ''"
      [compareWith]="to['compareWith']"
    >
      <ng-container *ngIf="items | async as opts">
        <ng-container *ngFor="let opt of opts">
          <ng-option [value]="opt" [disabled]="opt.disabled">{{
            to['bindValue'] ? opt[to['bindValue']] : opt
          }}</ng-option>
        </ng-container>
      </ng-container>
    </ng-select>
  `,
})
export class NgSelectFormlyFieldInput extends FieldType<FieldTypeConfig> {
  get items() {
    if (!(this.to.options instanceof Observable)) {
      return of(this.to.options);
    }
    return this.to.options;
  }
}

@NgModule({
  declarations: [NgSelectFormlyFieldInput],
  exports: [NgSelectFormlyFieldInput],
  imports: [CommonModule, FormlyModule, ReactiveFormsModule, NgSelectModule],
})
export class NgSelectFormlyFieldInputModule {}
