import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RmvdDatePipeModule } from '../../pipes/rmvdDate.pipe';
import { ActionMenuComponentModule } from '../action-menu/action-menu.component.module';
import { DataObjectIconComponentModule } from '../dataobject-icon/dataobject-icon.component';
import { TagsComponentModule } from '../tags/tags.component.module';
import { ForwardcurveReferencePreviewComponent } from './forwardcurve-reference-preview.component';

@NgModule({
  declarations: [ForwardcurveReferencePreviewComponent],
  exports: [ForwardcurveReferencePreviewComponent],
  imports: [
    CommonModule,
    TagsComponentModule,
    ActionMenuComponentModule,
    RmvdDatePipeModule,
    DataObjectIconComponentModule,
  ],
})
export class ForwardcurveReferencePreviewComponentModule {}
