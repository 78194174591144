import { inject, Injectable } from '@angular/core';
import {
  MatDateFormats,
  MAT_DATE_FORMATS,
  NativeDateAdapter,
} from '@angular/material/core';
import { toRMVDDateString } from 'src/app/shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
  private dateFormats: MatDateFormats = inject(MAT_DATE_FORMATS);
  override format(date: Date, displayFormat: Object): string {
    if (!this.isValid(date)) {
      throw Error('NativeDateAdapter: Cannot format invalid date.');
    }

    // We use RMVDDateString only for displaying DateInput
    if (displayFormat === this.dateFormats.display.dateInput) {
      return toRMVDDateString(date);
    }

    return super.format(date, displayFormat);
  }
}
