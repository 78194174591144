import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ActionButtonWithDropdownComponent } from './action-button-with-dropdown.component';

@NgModule({
  declarations: [ActionButtonWithDropdownComponent],
  exports: [ActionButtonWithDropdownComponent],
  imports: [CommonModule, RouterModule, NgClickOutsideDirective],
})
export class ActionButtonWithDropdownComponentModule {}
