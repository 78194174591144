import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { AggregatedScenarioResultPreviewComponentModule } from '../aggregatedscenarioresult-preview/aggregatedscenarioresult-preview.component.module';
import { CalculatedTimeSeriesPreviewComponentModule } from '../calculatedtimeseries-preview/calculatedtimeseries-preview.component.module';
import { EscalatedForwardCurveAsOfDatePreviewComponentModule } from '../data-management-previews/escalated-forwardcurve-asofdate-preview.component.module';
import { EscalatedForwardCurveReferencePreviewComponentModule } from '../data-management-previews/escalated-forwardcurve-reference-preview.component.module';
import { DealTermPreviewComponentModule } from '../dealterm-preview/dealterm-preview.component.module';
import { ForwardcurveReferencePreviewComponentModule } from '../forwardcurve-reference-preview/forwardcurve-reference-preview.component.module';
import { GenericTimeSeriesPreviewComponentModule } from '../generic-timeseries-preview/generic-timeseries-preview.component.module';
import { HistoricDatasetGenerationProfilePreviewComponentModule } from '../historic-dataset-generation-profile-preview/historic-dataset-generation-profile-preview.component.module';
import { HistoricDatasetPreviewComponentModule } from '../historic-dataset-preview/historic-dataset-preview.component.module';
import { ModelCalibrationPreviewComponentModule } from '../modelcalibration-preview/modelcalibration-preview.component.module';
import { ModelResultPreviewComponentModule } from '../modelresult-preview/modelresult-preview.component.module';
import { TimeSeriesRevisionPreviewComponentModule } from '../timeseries-revision-preview/timeseries-revision-preview.component.module';
import { ValuationRunPreviewComponentModule } from '../valuation-run-preview/valuation-run-preview.component.module';
import { ValuationTaskPreviewComponentModule } from '../valuation-task-preview/valuation-task-preview.component.module';
import { ValuationRunResultsPreviewComponentModule } from '../valuationrunresults-preview/valuationrunresults-preview.component.module';
import { VolatilityReferencePreviewComponentModule } from '../volatility-reference-preview/volatility-reference-preview.component.module';
import { DetailContainerComponent } from './detail-container.component';

@NgModule({
  declarations: [DetailContainerComponent],
  exports: [DetailContainerComponent],
  imports: [
    CommonModule,
    AgGridModule,
    ReactiveFormsModule,
    GenericTimeSeriesPreviewComponentModule,
    ModelResultPreviewComponentModule,
    AggregatedScenarioResultPreviewComponentModule,
    ForwardcurveReferencePreviewComponentModule,
    VolatilityReferencePreviewComponentModule,
    DealTermPreviewComponentModule,
    ModelCalibrationPreviewComponentModule,
    ValuationTaskPreviewComponentModule,
    ValuationRunPreviewComponentModule,
    ValuationRunResultsPreviewComponentModule,
    TimeSeriesRevisionPreviewComponentModule,
    EscalatedForwardCurveReferencePreviewComponentModule,
    EscalatedForwardCurveAsOfDatePreviewComponentModule,
    CalculatedTimeSeriesPreviewComponentModule,
    HistoricDatasetPreviewComponentModule,
    HistoricDatasetGenerationProfilePreviewComponentModule,
  ],
})
export class DetailContainerComponentModule {}
