import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BackgroundJobObjectType,
  BackgroundJobTypes,
  JobStatusEnum,
} from 'src/app/models/backgroundJobs.model';

@Component({
  selector: 'app-background-jobs-icon',
  template: `
    <div class="flex">
      <ng-container [ngSwitch]="bj.jobStatus.status">
        <ng-container *ngSwitchCase="jobStatusValues.Queued">
          <span class="text-blue-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="jobStatusValues.Processing">
          <span class="text-yellow-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="jobStatusValues.Succeeded">
          <span class="text-green-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="jobStatusValues.Failed">
          <span class="text-red-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              />
            </svg>
          </span>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span class="text-gray-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              />
            </svg>
          </span>
        </ng-container>
      </ng-container>
      <ng-container [ngSwitch]="bj.objectType">
        <ng-container
          *ngSwitchCase="
            jobTypeValues.TimeSeriesMetaDataAnalyticalSandboxMigration
          "
        >
          <app-analyticssandbox-metadata-icon
            class="ml-2"
          ></app-analyticssandbox-metadata-icon>
        </ng-container>
        <ng-container
          *ngSwitchCase="jobTypeValues.TimeSeriesDataAnalyticalSandboxMigration"
        >
          <app-analyticssandbox-icon class="ml-2"></app-analyticssandbox-icon>
        </ng-container>
        <ng-container *ngSwitchCase="jobTypeValues.FmpModelValuationRun">
          <app-execute-valuationtask-icon
            class="ml-2"
          ></app-execute-valuationtask-icon>
        </ng-container>
        <ng-container *ngSwitchCase="jobTypeValues.ForwardCurveCsvUpload">
          <app-timeseries-icon class="ml-2"></app-timeseries-icon>
        </ng-container>
        <ng-container
          *ngSwitchCase="jobTypeValues.TimeSeriesAnalyticalSandboxDataPurge"
        >
          <app-purge-analytical-sandbox-icon
            class="ml-2"
          ></app-purge-analytical-sandbox-icon>
        </ng-container>
        <ng-container
          *ngSwitchCase="jobTypeValues.ImportEscalatedForwardCurveReference"
        >
          <app-import-icon class="ml-2"></app-import-icon>
        </ng-container>
        <ng-container *ngSwitchCase="jobTypeValues.ProcessEscalatedForwardCurvesPythonScanTimerTrigger">
          <app-escalated-forwardcurve-reference-icon
            class="ml-2"
          ></app-escalated-forwardcurve-reference-icon>
        </ng-container>
        <ng-container *ngSwitchCase="jobTypeValues.SynchronizeHistoricDatasetToBlobStorage">
          <app-historicdataset-icon class="ml-2"></app-historicdataset-icon>
        </ng-container>
        <ng-container *ngSwitchCase="jobTypeValues.FmpModelCalibrationRun">
          <app-modelcalibration-icon class="ml-2"></app-modelcalibration-icon>
        </ng-container>
        <ng-container *ngSwitchCase="jobTypeValues.CalculateImfAndPercentiles">
          <app-calculator-icon class="ml-2"></app-calculator-icon>
        </ng-container>
        <ng-container *ngSwitchCase="jobTypeValues.MatrixProfileGenericCurve">
          <app-table-cells-icon class="ml-2"></app-table-cells-icon>
        </ng-container>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundJobsIconComponent {
  @Input() bj!: BackgroundJobTypes;
  jobStatusValues = JobStatusEnum;
  jobTypeValues = BackgroundJobObjectType;
}
