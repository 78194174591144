import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActionItemModel } from '../action-button/action-button.component';

@Component({
  selector: 'app-data-management-header',
  templateUrl: './data-management-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataManagementHeaderComponent {
  addItems: ActionItemModel[] = [
    {
      Heading: 'TimeSeries',
      Description: 'Create a new TimeSeries from a CSV/EXCEL file.',
      Route: '/addobject/timeseries',
    },
    {
      Heading: 'Synthetic Curve',
      Description: 'Generate a Synthetic Curve from Profile',
      Route: '/addobject/synthetic-curve',
    },
    {
      Heading: 'Historic Dataset',
      Description: 'Create a new Historic Dataset.',
      Route: '/addobject/historic-dataset',
    },
    {
      Heading: 'Deal Term',
      Description: 'Create a new deal term.',
      Route: '/addobject/dealterm',
    },
    {
      Heading: 'Forward Curve Reference',
      Description: 'Create a Reference to a CDS Forward Curve.',
      Route: '/addobject/forwardcurve-reference',
    },
    {
      Heading: 'Volatility Reference',
      Description: 'Create a Reference to a CDS Volatility Curve.',
      Route: '/addobject/volatility-reference',
    },
  ];
}
