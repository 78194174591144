import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { map, Observable, of, startWith, filter, switchMap } from 'rxjs';

@Component({
  selector: 'formly-field-autocomplete',
  template: `
    <ng-container *ngIf="filteredItems | async as data">
      <input
        type="text"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [class.input-is-invalid]="showError"
        [placeholder]="to.placeholder ?? ''"
        [matAutocomplete]="auto"
        class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md read-only:bg-gray-100"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let datum of data" [value]="datum">
          {{ datum }}
        </mat-option>
      </mat-autocomplete>
    </ng-container>
  `,
})
export class NgAutocompleteFormlyFieldInput
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  public get items(): Observable<string[]> {
    if (!(this.to.options instanceof Observable)) {
      return of((this.to.options ?? []).map((o) => o?.toString()));
    }
    return this.to.options;
  }

  filteredItems: Observable<string[]> = of([]);

  ngOnInit() {
    this.filteredItems = this.formControl.valueChanges.pipe(
      startWith(''),
      switchMap((value) => this._filter(value || ''))
    );
  }

  private _filter(value: string): Observable<string[]> {
    const filterValue = this._normalizeValue(value);
    return this.items.pipe(
      map((vals: string[]) =>
        vals
          .map((val) => this._normalizeValue(val))
          .filter((val) => val.includes(filterValue))
      )
    );
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
}

@NgModule({
  declarations: [NgAutocompleteFormlyFieldInput],
  exports: [NgAutocompleteFormlyFieldInput],
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
  ],
})
export class NgAutocompleteFormlyFieldInputModule {}
