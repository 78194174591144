import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RmvdDatePipeModule } from '../../pipes/rmvdDate.pipe';
import { ActionMenuComponentModule } from '../action-menu/action-menu.component.module';
import { DataObjectIconComponentModule } from '../dataobject-icon/dataobject-icon.component';
import { TagsComponentModule } from '../tags/tags.component.module';
import { TimeseriesPreviewComponentModule } from '../timeseries-preview/timeseries-preview.component.module';
import { TimeSeriesRevisionPreviewComponent } from './timeseries-revision-preview.component';
import { ExportTimeseriesComponentModule } from '../export-timeseries/export-timeseries.module';

@NgModule({
  declarations: [TimeSeriesRevisionPreviewComponent],
  exports: [TimeSeriesRevisionPreviewComponent],
  imports: [
    CommonModule,
    ActionMenuComponentModule,
    DataObjectIconComponentModule,
    TagsComponentModule,
    TimeseriesPreviewComponentModule,
    RmvdDatePipeModule,
    ExportTimeseriesComponentModule
  ],
})
export class TimeSeriesRevisionPreviewComponentModule {}
