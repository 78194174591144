import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackgroundJobsIconComponentModule } from '../background-jobs-Icon/background-jobs-Icon.component.module';
import { IconsModule } from '../icons/icons.component.module';
import { AgGridBackgroundJobsIconComponent } from './aggrid-background-jobs-Icon.component';

@NgModule({
  declarations: [AgGridBackgroundJobsIconComponent],
  exports: [AgGridBackgroundJobsIconComponent],
  imports: [CommonModule, IconsModule, BackgroundJobsIconComponentModule],
})
export class AgGridBackgroundJobsIconComponentModule {}
