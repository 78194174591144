<div
  class="flex justify-between items-center max-w-8xl mx-auto py-2 px-4 sm:px-6 lg:px-8"
>
  <h1 class="text-lg leading-6 font-semibold text-gray-900">
    Valuation Results
  </h1>
  <button
    type="button"
    routerLink="/valuationcockpit"
    queryParamsHandling="preserve"
    class="flex items-center bg-white rounded-md font-medium text-uniper hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4 font-medium"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15 19l-7-7 7-7"
      />
    </svg>
    <span class="" ml-2>Back to Valuation Cockpit</span>
  </button>
</div>
