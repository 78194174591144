import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
  } from '@angular/core';
  import { NavService } from '../../services/nav.service';
  
  @Component({
    selector: 'app-action-button-disabled',
    template: `
      <div>
        <label id="listbox-label" class="sr-only"> Add Actions </label>
        <div class="relative">
          <div class="inline-flex shadow-sm rounded-md divide-x divide-blue-600">
            <div
              class="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-blue-600"
            >
              <button
                type="button"
                disabled
                class="relative inline-flex items-center bg-blue-300 p-2 rounded-md text-sm font-medium text-white hover:bg-blue-300 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-300"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
              >
                <span class="ml-2.5 text-sm font-medium">Add Object</span>
                <svg
                  class="h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
          <ul
            *ngIf="show"
            class="origin-top-right absolute z-10 right-0 mt-2 w-96 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-0"
          >
          </ul>
        </div>
      </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
  export class ActionButtonDisabledComponent {
    show: boolean = false;
  
    constructor(private nav: NavService) {}
  
  }