import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { TimeseriesItem } from 'src/app/models/timeseries.model';
import { CachedPreviewTimeseriesService } from 'src/app/shared/services/cachedPreviewTimeSeries.service';
import * as d3 from 'd3';
import { shouldRenderGraphAsSteps } from '../../utils/utils';
import { filterNullOrUndefined } from '../../utils/operators';
import { HttpClient } from '@microsoft/signalr';
import { ApiService } from '../../services/api.service';
import { ExportTimeseriesModalComponent } from '../export-timeseries/export-timeseries-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';

@Component({
  selector: 'app-timeseries-preview',
  template: `
    <app-line-chart
      [xAccessor]="xAccessor"
      [yAccessor]="yAccessor"
      [data]="timeseries$ | async"
      [timezone]="(timezone$ | async)!"
      [yAxisLabel]="uom$ | async"
      [renderAsSteps]="renderAsSteps$ | async"
      (contextmenu)="showContextMenu($event)"
    ></app-line-chart>
    <div class="legend-context-menu context-menu" style="display: none;">
    <ul>
      <li (click)="onExportTimeseries()">Export</li>
    </ul>
  </div>
  `,
  styles: [`
  .legend-context-menu {
    position: absolute;
    display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Add more styles as needed */
  }
  .context-menu {
    position: absolute;
    display: none;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
    
  .context-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .context-menu li {
    padding: 8px 12px;
    cursor: pointer;
  }
`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeseriesPreviewComponent implements OnInit {
  base64Data: string = 'your_base64_encoded_data_here';
  @Input() dataObject: any;
  private objectSubject = new BehaviorSubject<{
    revisionId: number;
    id: string;
    timezone: string;
    uom?: string | null;
    granularity: string;
    settlementPeriod?: string | null;
    objectType?: string;
    name?: string;
  } | null>(null);

  @Input() set object(
    object: {
      revisionId: number;
      id: string;
      timezone: string;
      uom?: string | null;
      granularity: string;
      settlementPeriod?: string | null;
      objectType?: string;
      name?: string;
    } | null
  ) {
    if (object) {
      this.objectSubject.next(object);
    }
  }

  //object$ = this.objectSubject.asObservable();
  timeseries$ = this.objectSubject.pipe(
    filter((object) => !!object),
    switchMap((object) =>
      this.previewTimeSeriesService
        .getPreviewTimeseries(object!.id, object!.revisionId)
        .pipe(catchError(() => of([])))
    )
  );

  timezone$ = this.objectSubject.pipe(
    filter((object) => !!object),
    map((object) => object!.timezone)
  ) as Observable<string>;

  renderAsSteps$ = this.objectSubject.pipe(
    filterNullOrUndefined(),
    map((object) => shouldRenderGraphAsSteps(object.granularity))
  ) as Observable<boolean>;

  uom$ = this.objectSubject.pipe(
    filter((object) => !!object),
    map((object) => object!.uom ?? '')
  ) as Observable<string>;

  dateParse = d3.timeParse('%Y-%m-%dT%H:%M:%S%Z');
  xAccessor = (d: TimeseriesItem) => this.dateParse(d.timestamp) as Date;
  yAccessor = (d: TimeseriesItem) => Number(d.value);

  constructor(
    private previewTimeSeriesService: CachedPreviewTimeseriesService,
    private simpleModalService: SimpleModalService
  ) {
  }

  ngOnInit(): void {
 
  }
  showContextMenu(event: MouseEvent) {
    event.preventDefault();
    const contextMenu: any = document.querySelector('.legend-context-menu');
  
    contextMenu.style.display = 'block';

    const offsetX = event.clientX;
    const offsetY = event.clientY;
  
    contextMenu.style.left = offsetX + 'px';
    contextMenu.style.top = offsetY + 'px';
  
    document.addEventListener('click', this.handleOutsideClick);  // Stop propagation to prevent the click event from closing the context menu immediately
  }

  handleOutsideClick = () => {
    const contextMenu: any = document.querySelector('.legend-context-menu');
    contextMenu.style.display = 'none';

    // Remove the click event listener from the document
    document.removeEventListener('click', this.handleOutsideClick);
  };
  
    onExportTimeseries() {
      this.simpleModalService
          .addModal(ExportTimeseriesModalComponent, {objectSubject: this.objectSubject, dataObject: this.dataObject})
          .subscribe((_) => { });
      
    }  
}
