import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LineChartComponentModule } from '../line-chart/line-chart.component.module';
import { TimeseriesPreviewComponent } from './timeseries-preview.component';

@NgModule({
  declarations: [TimeseriesPreviewComponent],
  exports: [TimeseriesPreviewComponent],
  imports: [CommonModule, LineChartComponentModule],
})
export class TimeseriesPreviewComponentModule {}
