import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from  'ag-grid-enterprise';
 
 

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-041346 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Uniper IT GmbH )_is_granted_a_( Multiple Applications )_Developer_License_for_( 3 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 21 May 2024 )____[v2]_MTcxNjI0NjAwMDAwMA==c3081726ceb98edb2ab21c6935b817ca");


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
