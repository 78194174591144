import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Notification } from '../../../models/notification.model';

@Component({
  selector: 'app-notification-error',
  templateUrl: './notification-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationErrorComponent {
  @Input() notification: Notification | null = null;
  @Output() dismiss = new EventEmitter<void>();

  onDismiss() {
    this.dismiss.emit();
  }
}
