import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashboardWidgetComponent } from './dashboard-widget.component';
import { DataObjectsDocumentsComponentModule } from 'src/app/pages/data-management/components/dataobject-documents/dataobject-documents.module';
import { DataObjectFiltersComponentModule } from 'src/app/pages/data-management/components/dataobject-filters/dataobject-filters.component.module';
import { DetailContainerComponentModule } from 'src/app/shared/components/detail-container/detail-container.component.module';
import { BackgroundJobsGridComponentModule } from 'src/app/pages/background-jobs/components/background-jobs-grid/background-jobs-grid.component.module';
import { BackgroundJobsFiltersComponentModule } from 'src/app/pages/background-jobs/components/background-jobs-filters/background-jobs-filters.component.module';
import { BackgroundJobsPreviewContainerComponentModule } from 'src/app/pages/background-jobs/components/backgroundjobs-preview-container/background-jobs-preview-container.component.module';
import {CdkDrag, DragDropModule} from '@angular/cdk/drag-drop';
import { DragHandleWrapperComponent } from './drag-handle-wrapper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardWidgetHeaderComponent } from './dashboard-widget-header.component';
import { FormlyModule } from '@ngx-formly/core';

@NgModule({
  declarations: [DashboardWidgetComponent, DragHandleWrapperComponent, DashboardWidgetHeaderComponent],
  exports: [DashboardWidgetComponent, DashboardWidgetHeaderComponent ],
  imports: [
    CommonModule,
    DataObjectsDocumentsComponentModule,
    DataObjectFiltersComponentModule,
    DetailContainerComponentModule,
    BackgroundJobsGridComponentModule,
    CdkDrag,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    // FormlyBootstrapModule,
  ],
})
export class DashboardWidgetComponentModule {}
