import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  notification$ = this.notificationService.notification$;
  constructor(private notificationService: NotificationService) {}
  onDismiss() {
    this.notificationService.clear();
  }
}
