import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RmvdDatePipeModule } from '../../pipes/rmvdDate.pipe';
import { DataObjectIconComponentModule } from '../dataobject-icon/dataobject-icon.component';
import { AggregatedScenarioResultPreviewComponent } from './aggregatedscenarioresult-preview.component';

@NgModule({
  declarations: [AggregatedScenarioResultPreviewComponent],
  exports: [AggregatedScenarioResultPreviewComponent],
  imports: [
    CommonModule,
    DataObjectIconComponentModule,
    RmvdDatePipeModule,
  ],
})
export class AggregatedScenarioResultPreviewComponentModule {}
