import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  PopupRequest,
} from '@azure/msal-browser';
import {
  filter,
  firstValueFrom,
  map,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActionButtonWithDropdownViewModel } from './models/ActionButtonWithDropdown.model';
import { ApiService } from './shared/services/api.service';
import { GraphBasketService } from './shared/services/graphBasket.service';
import { NavService } from './shared/services/nav.service';
import { NotificationService } from './shared/services/notification.service';
import { SignalrService } from './shared/services/signalr.service';
import { UniperAuthService } from './shared/services/uniperAuth.service';
import { getBrowserCultureLang } from './shared/utils/browser';
import { PPA_DROPDOWN_OPTIONS, TOOLTIPS_DATA } from './shared/utils/config';
import { LocalStorageService } from 'ngx-store';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  isLoggedIn = false;
  loginDetails = 'Not Logged In';

  private readonly _destroying$ = new Subject<void>();

  actionButtonViewModel$: Observable<ActionButtonWithDropdownViewModel> =
    this.graphBasketService.graphBasketItems$.pipe(
      map((graphBasketItems) => {
        return {
          title: `Graph Basket ${
            this.graphBasketCount ? `(${this.graphBasketCount})` : ''
          }`,
          action: '/graph-basket',
          dropdownOptions: [
            {
              title: 'Clear',
              action: () => {
                this.graphBasketService.clearBasket();
                if (this.router.url === '/graph-basket') {
                  this.navService.goToDataManagement();
                }
              },
            },
          ],
        } as ActionButtonWithDropdownViewModel;
      })
    );

  get graphBasketCount() {
    return this.graphBasketService.getBasketCount();
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msal: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private auth: UniperAuthService,
    private graphBasketService: GraphBasketService,
    private adapter: DateAdapter<Date>,
    private msgSvc: NotificationService,
    private api: ApiService,
    private localStorage: LocalStorageService,
    private router: Router,
    private navService: NavService,
    private signalr: SignalrService
  ) {}

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => this.setLoginDisplay());

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => this.setLoginDisplay());

    const browserLocale = getBrowserCultureLang(window);
    if (browserLocale) {
      this.adapter.setLocale(browserLocale);
    }

    this.signalr.connect();
    TOOLTIPS_DATA(this.api)
    PPA_DROPDOWN_OPTIONS(this.api, this.localStorage)
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.msal
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe({
          next: (result) => {
            this.setLoginDisplay();
          },
          error: (error) => console.log(error),
        });
    } else {
      this.msal.loginPopup().subscribe({
        next: (result) => {
          this.setLoginDisplay();
        },
        error: (error) => console.log(error),
      });
    }
  }

  logout() {
    this.msal.logoutPopup({
      mainWindowRedirectUri: '/',
    });
  }

  setLoginDisplay() {
    this.isLoggedIn = this.auth.IsLoggedIn;
    this.loginDetails = this.auth.LoggedOnUser?.displayText || 'Not Logged In';
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  async appInfo(): Promise<void> {
    var apiInfo = await firstValueFrom(this.api.GetApiInfo());
    this.msgSvc.info(
      `RMVD Application Version: ${environment.buildNumber} [${environment.environment}]. ${apiInfo.name}: ${apiInfo.build} [${apiInfo.environment}]`
    );
  }
}
