import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_NATIVE_DATE_FORMATS,
} from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalGuard, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { StoreModule } from '@ngrx/store';
import { SimpleModalModule } from 'ngx-simple-modal';
import { WebStorageModule } from 'ngx-store';
import { TimeagoModule } from 'ngx-timeago';
import { environment } from 'src/environments/environment';
import { APP_INITIALIZER_PROVIDERS } from './shared/app-initializers';
import { ActionButtonWithDropdownComponentModule } from './shared/components/action-button-with-dropdown/action-button-with-dropdown.component.module';
import { DetailPopupModalComponentModule } from './shared/components/detail-popup-modal/detail-popup-modal.component.module';
import { HeaderComponentModule } from './shared/components/header/header.component.module';
import { LoadingComponentModule } from './shared/components/loading/loading.component.module';
import { LoggedOnUserComponentModule } from './shared/components/loggedonuser/loggedonuser.component.module';
import { NotificationComponentModule } from './shared/components/notification/notification.component.module';
import { FormlyComponentsModule } from './shared/formly-components/formly-components.module';
import { HTTP_INTERCEPTOR_PROVIDERS } from './shared/interceptors';
import {
  azureBlobStorageFactory,
  BLOB_STORAGE_TOKEN,
} from './shared/services/blobStorage.service';
import { CustomDateAdapter } from './shared/services/customDateAdapter';
import { WINDOW } from './shared/tokens/window.token';
import { getBrowserCultureLang } from './shared/utils/browser';
import { backgrounJobUpdatesReducer, toolTipReducer } from './state/background-jobs.state';
import { reducers } from './shared/components/root.reducer';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    MatNativeDateModule,
    HttpClientModule,
    MatNativeDateModule,
    WebStorageModule.forRoot(),
    SimpleModalModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.clientId,
          authority: environment.authority,
          redirectUri: environment.redirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Popup,
        authRequest: {
          scopes: environment.scope,
        },
      },
      {
        interactionType: InteractionType.Popup, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          [`${environment.apiUrl}*`, environment.scope],
        ]),
      }
    ),
    StoreModule.forRoot(reducers),
    TimeagoModule.forRoot(),
    FormlyComponentsModule,
  ],
  exports: [
    HeaderComponentModule,
    LoadingComponentModule,
    NotificationComponentModule,
    ActionButtonWithDropdownComponentModule,
    LoggedOnUserComponentModule,
    CommonModule,
    DetailPopupModalComponentModule
  ],
  providers: [
    HTTP_INTERCEPTOR_PROVIDERS,
    APP_INITIALIZER_PROVIDERS,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
    {
      provide: BLOB_STORAGE_TOKEN,
      useFactory: azureBlobStorageFactory,
    },
    MsalGuard,
    {
      provide: LOCALE_ID,
      useFactory: (window: Window) => {
        return getBrowserCultureLang(window);
      },
      deps: [WINDOW],
    },
  ], // these should be singleton
})
export class CoreModule {
  // Ensure that CoreModule is only loaded into AppModule
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `CoreModule has already been loaded. Import this module in the AppModule only.`
      );
    }
  }
}
