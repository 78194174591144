import { APP_INITIALIZER } from '@angular/core';
import { ReferenceDataStore } from '../services/referenceData.store';

export const APP_INITIALIZER_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [ReferenceDataStore],
    useFactory: (referenceDataStore: ReferenceDataStore) => {
      return () => {
        return referenceDataStore.referenceDataLoaded$;
      };
    },
  },
];
