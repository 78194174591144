import { NgModule } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import {
  ButtonGroupFormlyFieldInput,
  ButtonGroupFormlyFieldInputModule,
} from './buttonGroupFormlyFieldInput';
import {
  CheckboxFormlyFieldInput,
  CheckboxFormlyFieldInputModule,
} from './checkboxFormlyFieldInput';
import {
  DateFormlyFieldInput,
  DateFormlyFieldInputModule,
} from './dateFormlyFieldInput';
import {
  FieldWrapperComponent,
  FieldWrapperComponentModule,
} from './fieldWrapperComponent';
import {
  InputFormlyFieldInput,
  InputFormlyFieldInputModule,
} from './inputFormlyFieldInput';
import {
  NgAutocompleteFormlyFieldInput,
  NgAutocompleteFormlyFieldInputModule,
} from './ngAutocompleteFormlyFieldInput';
import {
  NgMultiSelectFormlyFieldInput,
  NgMultiSelectFormlyFieldInputModule,
} from './ngMultiSelectFormlyFieldInput';
import { NgSelectFormlyFieldInput } from './ngSelectFormlyFieldInput';
import {
  PercentilesAndImfFormlyField,
  PercentilesAndImfFormlyFieldModule,
} from './percentilesAndImfFormlyField';
import {
  RepeatFormlyField,
  RepeatFormlyFieldModule,
} from './repeatFormlyField';
import {
  RepeatFormlyFieldNoAddRemove,
  RepeatFormlyFieldNoAddRemoveModule,
} from './repeatFormlyFieldNoAddRemove';
import {
  TimeseriesPreviewFormlyField,
  TimeseriesPreviewFormlyFieldModule,
} from './timeseriesPreviewFormlyField';

export function minlengthValidationMessage(err: any, field: any) {
  return `Should have atleast ${field.templateOptions.minLength} characters`;
}

export function maxlengthValidationMessage(err: any, field: any) {
  return `This value should be less than or equal to ${field.templateOptions.maxLength} characters`;
}

export function minValidationMessage(err: any, field: any) {
  return `This value should be more than or equal to ${field.templateOptions.min}`;
}

export function maxValidationMessage(err: any, field: any) {
  return `This value should be less than ${field.templateOptions.max}`;
}

@NgModule({
  imports: [
    ButtonGroupFormlyFieldInputModule,
    DateFormlyFieldInputModule,
    FieldWrapperComponentModule,
    InputFormlyFieldInputModule,
    CheckboxFormlyFieldInputModule,
    NgMultiSelectFormlyFieldInputModule,
    NgAutocompleteFormlyFieldInputModule,
    TimeseriesPreviewFormlyFieldModule,
    PercentilesAndImfFormlyFieldModule,
    RepeatFormlyFieldModule,
    RepeatFormlyFieldNoAddRemoveModule,
    FormlyModule.forRoot({
      types: [
        {
          name: 'repeat',
          component: RepeatFormlyField,
        },
        {
          name: 'repeat-no-add-remove',
          component: RepeatFormlyFieldNoAddRemove,
        },
        {
          name: 'select',
          component: NgSelectFormlyFieldInput,
          wrappers: ['default'],
        },
        {
          name: 'multi-select',
          component: NgMultiSelectFormlyFieldInput,
          wrappers: ['default'],
        },
        {
          name: 'autocomplete',
          component: NgAutocompleteFormlyFieldInput,
          wrappers: ['default'],
        },
        {
          name: 'input',
          component: InputFormlyFieldInput,
          wrappers: ['default'],
        },
        {
          name: 'checkbox',
          component: CheckboxFormlyFieldInput,
          wrappers: ['default'],
        },
        {
          name: 'buttongroup',
          component: ButtonGroupFormlyFieldInput,
          wrappers: ['default'],
        },
        {
          name: 'datepicker',
          component: DateFormlyFieldInput,
          wrappers: ['default'],
        },
        {
          name: 'timeseries-preview',
          component: TimeseriesPreviewFormlyField,
        },
        {
          name: 'percentiles-and-imf',
          component: PercentilesAndImfFormlyField,
          wrappers: ['default'],
        },
      ],
      wrappers: [{ name: 'default', component: FieldWrapperComponent }],
      validationMessages: [
        { name: 'required', message: 'Required' },
        { name: 'minlength', message: minlengthValidationMessage },
        { name: 'maxlength', message: maxlengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
      ],
      extras: {
        resetFieldOnHide: true
      }
    }),
  ],
})
export class FormlyComponentsModule {}
