import { Inject, Injectable, Optional } from '@angular/core';
import { LocalStorageService } from 'ngx-store';
import { BehaviorSubject } from 'rxjs';
import { GraphBasketItem } from '../../models/graphBasket.model';
import { GRAPH_BASKET_KEY } from '../tokens/graphBasketKey.token';

@Injectable({
  providedIn: 'root',
})
export class GraphBasketService {
  private graphBasketItemsSubject = new BehaviorSubject<
    Array<GraphBasketItem | GraphBasketItem[]>
  >([]);
  graphBasketItems$ = this.graphBasketItemsSubject.asObservable();

  constructor(
    private localStorage: LocalStorageService,
    @Optional() @Inject(GRAPH_BASKET_KEY) private key: string = 'Graph_Basket'
  ) {
    this.updateBasket(this.getBasket());
  }

  addToBasket(object: GraphBasketItem) {
    const currentBasket = this.getBasket();
    if (!this.isAlreadyAdded(currentBasket, object)) {
      currentBasket.push(object);
      this.updateBasket(currentBasket);
    }
  }

  addToBasketAsAGroup(objects: GraphBasketItem[]) {
    let updatedBasket = this.getBasket();
    // Remove any existing items that are in this group
    objects.forEach((graphBasketItem) => {
      updatedBasket = this.getBasketAfterFilteringItemToRemove(
        updatedBasket,
        graphBasketItem
      );
    });

    // Add the basket items as one group
    if (objects.length) {
      updatedBasket.push(objects);
      this.updateBasket(updatedBasket);
    }
  }

  removeBasket(object: Pick<GraphBasketItem, 'id' | 'revisionId'>) {
    const currentBasket = this.getBasket();
    const updatedBasket = this.getBasketAfterFilteringItemToRemove(
      currentBasket,
      object
    );
    this.updateBasket(updatedBasket);
  }

  clearBasket() {
    this.localStorage.set(this.key, []);
    this.graphBasketItemsSubject.next([]);
  }

  getBasket(): Array<GraphBasketItem | GraphBasketItem[]> {
    return this.localStorage.get(this.key) || [];
  }

  getBasketCount(): number {
    return this.getBasket().reduce((prev, curr) => {
      if (Array.isArray(curr)) {
        return prev + curr.length;
      }
      return prev + 1;
    }, 0);
  }

  private updateBasket(basket: Array<GraphBasketItem | GraphBasketItem[]>) {
    this.localStorage.set(this.key, basket);
    this.graphBasketItemsSubject.next(basket);
  }

  private getBasketAfterFilteringItemToRemove(
    currentBasket: Array<GraphBasketItem | GraphBasketItem[]>,
    object: Pick<GraphBasketItem, 'id' | 'revisionId'>
  ) {
    const updatedBasket: Array<GraphBasketItem | GraphBasketItem[]> = [];
    const isItemToRemove = (
      item: GraphBasketItem,
      itemToRemove: Pick<GraphBasketItem, 'id' | 'revisionId'>
    ) =>
      item.id === itemToRemove.id &&
      item.revisionId === itemToRemove.revisionId;

    currentBasket.forEach((basketItem) => {
      if (Array.isArray(basketItem)) {
        const filteredArray = basketItem.filter(
          (arrayItem) => !isItemToRemove(arrayItem, object)
        );
        if (filteredArray.length) {
          updatedBasket.push(filteredArray);
        }
        return;
      }
      if (!isItemToRemove(basketItem, object)) {
        updatedBasket.push(basketItem);
      }
    });
    return updatedBasket;
  }

  private isAlreadyAdded(
    basket: Array<GraphBasketItem | GraphBasketItem[]>,
    itemToAdd: GraphBasketItem
  ) {
    return !!basket.find((item) => {
      if (Array.isArray(item)) {
        return item.find(
          (arrayItem) =>
            arrayItem.id === itemToAdd.id &&
            arrayItem.revisionId === itemToAdd.revisionId
        );
      }
      return (
        item.id === itemToAdd.id && item.revisionId === itemToAdd.revisionId
      );
    });
  }
}
