import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-repeat-no-add-remove',
  template: `
    <formly-field
      *ngFor="let field of field.fieldGroup"
      [field]="field"
    ></formly-field>
  `,
})
export class RepeatFormlyFieldNoAddRemove extends FieldArrayType {}

@NgModule({
  declarations: [RepeatFormlyFieldNoAddRemove],
  exports: [RepeatFormlyFieldNoAddRemove],
  imports: [CommonModule, FormlyModule],
})
export class RepeatFormlyFieldNoAddRemoveModule {}
