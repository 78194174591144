import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { WrappedTags } from 'src/app/models/tags.model';
import { DatePickerOptions } from '../../models/dateFormlyFieldInput.model';
import { TimeseriesPreviewDetails } from '../../models/timeseries.model';

let toolTipData = null;

export type FormlyKeyValue<Model, Type> = {
  [Key in keyof Model]: Model[Key] extends Type ? Key & string : never;
}[keyof Model];

export function requiredNumber<TModel = any>(
  key: FormlyKeyValue<TModel, number | null>,
  label: string,
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: any,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'input',
    className: className,
    ...configOverrides,
    templateOptions: {
      required: true,
      label: label,
      type: 'number',
      ...configOverrides?.templateOptions,
      uuid: id,

    },
  };
}

export function optionalNumber<TModel = any>(
  key: FormlyKeyValue<TModel, number | null | undefined>,
  label: string,
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: any,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'input',
    className: className,
    ...configOverrides,
    templateOptions: {
      required: false,
      label: label,
      type: 'number',
      ...configOverrides?.templateOptions,
      uuid: id,

    },
  };
}

export function formlyGroup<TModel = any>(
  key: FormlyKeyValue<TModel, object>,
  wrappers: string[] = [],
  configOverrides?: FormlyFieldConfig,
  fieldGroup: FormlyFieldConfig[] = []
): FormlyFieldConfig {
  return {
    key: key,
    wrappers,
    ...configOverrides,
    templateOptions: {
      ...configOverrides?.templateOptions,
    },
    fieldGroup,
  };
}

export function formlyArray<TModel = any>(
  key: FormlyKeyValue<TModel, Array<any>>,
  repeatingSectionConfig: FormlyFieldConfig,
  configOverrides?: FormlyFieldConfig,
  canAddAndRemoveArrayItems = true
): FormlyFieldConfig {
  return {
    key: key,
    ...configOverrides,
    ...(canAddAndRemoveArrayItems
      ? { type: 'repeat' }
      : { type: 'repeat-no-add-remove' }),
    templateOptions: {
      ...configOverrides?.templateOptions,
    },
    ...(repeatingSectionConfig ? { fieldArray: repeatingSectionConfig } : {}),
  };
}

export function requiredInput<TModel = any>(
  key: FormlyKeyValue<TModel, string | null | undefined>,
  label: string,
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: any,
  
): FormlyFieldConfig {
  return {
    key: key,
    type: 'input',
    className: className,
    ...configOverrides,
    templateOptions: {
      required: true,
      label: label,
      type: 'text',
      ...configOverrides?.templateOptions,
      uuid: id,


    },
  };
}

export function optionalInput<TModel = any>(
  key: FormlyKeyValue<TModel, string | null | undefined>,
  label: string,
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: any,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'input',
    className: className,
    ...configOverrides,
    templateOptions: {
      required: false,
      label: label,
      type: 'text',
      ...configOverrides?.templateOptions,
      uuid: id,


    },
  };
}

export function checkbox<TModel = any>(
  key: FormlyKeyValue<TModel, string | null | undefined>,
  label: string
): FormlyFieldConfig {
  return {
    key: key,
    type: 'checkbox',
    className: 'col-span-6 sm:col-span-3 px-3',
    templateOptions: {
      required: false,
      label: label,
    },
  };
}

export function optionalDate<TModel = any>(
  key: FormlyKeyValue<TModel, Date | null | undefined>,
  label: string,
  className: string = 'col-span-6 sm:col-span-3 px-3',
  datepickerOptions?: DatePickerOptions,
  configOverrides?: FormlyFieldConfig,
  id?: any,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'datepicker',
    className: className,
    ...configOverrides,
    templateOptions: {
      required: false,
      label: label,
      ...configOverrides?.templateOptions,
      datepickerOptions,
      uuid: id,


    },
  };
}

export function requiredDate<TModel = any>(
  key: FormlyKeyValue<TModel, Date | null>,
  label: string,
  className: string = 'col-span-6 sm:col-span-3 px-3',
  datepickerOptions?: DatePickerOptions,
  configOverrides?: FormlyFieldConfig,
  id?: any,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'datepicker',
    className: className,
    ...configOverrides,
    templateOptions: {
      required: true,
      label: label,
      ...configOverrides?.templateOptions,
      datepickerOptions,
      uuid: id,


    },
  };
}

export function optionalSelect<TModel = any>(
  key: FormlyKeyValue<TModel, any | null | undefined>,
  label: string,
  options: Observable<any[]> | any[] = [],
  bindValue: string = '',
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: any,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'select',
    className: className,
    ...configOverrides,
    templateOptions: {
      label: label,
      bindValue: bindValue,
      options: options,
      required: false,
      ...configOverrides?.templateOptions,
      uuid: id,


    },
  };
}

export function timeseriesPreview<TModel = any>(
  key: FormlyKeyValue<TModel, TimeseriesPreviewDetails | null | undefined>,
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: any,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'timeseries-preview',
    className: className,
    ...configOverrides,
    templateOptions: {
      required: false,
      ...configOverrides?.templateOptions,
      uuid: id
    },
  };
}

export function requiredSelect<TModel = any>(
  key: FormlyKeyValue<TModel, any | null>,
  label: string,
  options: Observable<any[]> | any[] = [],
  bindValue: string = '',
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: string,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'select',
    className: className,
    ...configOverrides,
    templateOptions: {
      label: label,
      uuid: id,

      bindValue: bindValue,
      options: options,
      required: true,
      ...configOverrides?.templateOptions,
    },
  };
}

export function requiredMultiSelect<TModel = any>(
  key: FormlyKeyValue<TModel, any[] | null>,
  label: string,
  options: Observable<any[]> | any[] = [],
  bindLabel: string = 'label',
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: string,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'multi-select',
    className: className,
    ...configOverrides,
    templateOptions: {
      label: label,
      bindLabel,
      options: options,
      required: true,
      ...configOverrides?.templateOptions,
      uuid: id,

    },
  };
}

export function optionalMultiSelect<TModel = any>(
  key: FormlyKeyValue<TModel, any[] | null | undefined>,
  label: string,
  options: Observable<any[]> | any[] = [],
  bindLabel: string = 'label',
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: string,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'multi-select',
    className: className,
    ...configOverrides,
    templateOptions: {
      label: label,
      bindLabel,
      options: options,
      required: false,
      ...configOverrides?.templateOptions,
      uuid: id,

    },
  };
}

export function requiredTags<TModel = any>(
  key: FormlyKeyValue<TModel, number[] | string[] | null>,
  label: string,
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: any,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'multi-select',
    className: className,
    ...configOverrides,
    templateOptions: {
      addTag: true,
      selectOnTab: true,
      isOpen: false,
      label: label,
      options: [],
      required: true,
      ...configOverrides?.templateOptions,
      uuid: id,


    },
  };
}

export function optionalTags<TModel = any>(
  key: FormlyKeyValue<
    TModel,
    | string[]
    | number[]
    | WrappedTags<string>
    | WrappedTags<number>
    | null
    | undefined
  >,
  label: string,
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  subtext?: string,
  allowDuplicateTags?: boolean,
  id?: any,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'multi-select',
    className: className,
    ...configOverrides,
    templateOptions: {
      ...(allowDuplicateTags ? { allowDuplicateTags } : {}),
      addTag: allowDuplicateTags
        ? function (tag: string) {
            return { tag };
          }
        : true,
      selectOnTab: true,
      isOpen: false,
      label: label,
      options: [],
      required: false,
      ...(subtext ? { subtext } : {}),
      ...configOverrides?.templateOptions,
      uuid: id,


    },
  };
}

export function optionalButtonGroup<TModel = any>(
  key: FormlyKeyValue<TModel, any | null | undefined>,
  label: string,
  
  options: Observable<any[]> | any[] = [],
  bindValue: string = '',
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: any,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'buttongroup',
    className: className,
    ...configOverrides,
    templateOptions: {
      label: label,
      bindValue: bindValue,
      options: options,
      ...configOverrides?.templateOptions,
      uuid: id,


    },
  };
}

export function requiredButtonGroup<TModel = any>(
  key: FormlyKeyValue<TModel, any | null>,
  label: string,
  options: Observable<any[]> | any[] = [],
  bindValue: string = '',
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: string,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'buttongroup',
    className: className,
    ...configOverrides,
    templateOptions: {
      required: true,
      label: label,
      bindValue: bindValue,
      options: options,
      ...configOverrides?.templateOptions,
      uuid: id,


    },
  };
}

export function autocompleteFormlyControl<TModel = any>(
  key: FormlyKeyValue<TModel, string | null | undefined>,
  label: string,
  options: Observable<string[]> | string[] = [],
  className: string = 'col-span-6 sm:col-span-3 px-3',
  configOverrides?: FormlyFieldConfig,
  id?: any,
  
): FormlyFieldConfig {
  
  return {
    key: key,
    type: 'autocomplete',
    className: className,
    ...configOverrides,
    templateOptions: {
      label: label,
      options: options,
      ...configOverrides?.templateOptions,
      uuid: id,


    },
  };
}

export function formSeperator(heading: string): FormlyFieldConfig {
  return {
    className: ' col-span-9',
    template: `
      <div class="border-b border-t border-gray-200 px-4 py-5 sm:px-6">
        <h4 class="leading-6 font-medium text-uniper">${heading}</h4>
      </div>
    `,
  };
}
