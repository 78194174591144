import { ChangeDetectionStrategy, Component, Input, Output, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { SimpleModalComponent } from 'ngx-simple-modal';
import { requiredSelect } from "../../formly-components/formlyHelpers";
import { BehaviorSubject, Subject } from "rxjs";
import { ApiService } from "../../services/api.service";
import { EXPORT_ICON } from "src/app/models/contextMenuIcons.model";
import { ConverterOptions } from "../../utils/config";
import * as XLSX from 'xlsx';
import { DataObjectTypeName } from "src/app/models/dataObject.model";

@Component({
  selector: 'app-export-timeseries-modal',
  template: `
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
      Background backdrop, show/hide based on modal state.

      Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
      Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
              <!-- Heroicon name: outline/exclamation-triangle -->
              <svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 493.525 493.525" xml:space="preserve">
                <g id="XMLID_30_">
                  <path id="XMLID_32_" d="M430.557,79.556H218.44c21.622,12.688,40.255,29.729,54.859,49.906h157.258
		c7.196,0,13.063,5.863,13.063,13.06v238.662c0,7.199-5.866,13.064-13.063,13.064H191.894c-7.198,0-13.062-5.865-13.062-13.064
		V222.173c-6.027-3.1-12.33-5.715-18.845-7.732c-3.818,11.764-12.105,21.787-23.508,27.781c-2.39,1.252-4.987,2.014-7.554,2.844
		v136.119c0,34.717,28.25,62.971,62.968,62.971h238.663c34.718,0,62.969-28.254,62.969-62.971V142.522
		C493.525,107.806,465.275,79.556,430.557,79.556z" />
                  <path id="XMLID_31_" d="M129.037,175.989c51.419,1.234,96.388,28.283,122.25,68.865c2.371,3.705,6.434,5.848,10.657,5.848
		c1.152,0,2.322-0.162,3.46-0.486c5.377-1.545,9.114-6.418,9.179-12.006c0-0.504,0-1.01,0-1.51
		c0-81.148-64.853-147.023-145.527-148.957V64.155c0-5.492-3.038-10.512-7.879-13.078c-2.16-1.139-4.533-1.707-6.889-1.707
		c-2.94,0-5.848,0.88-8.35,2.584L5.751,120.526C2.162,122.98,0.018,127.041,0,131.394c-0.017,4.338,2.113,8.418,5.687,10.902
		l100.17,69.451c2.518,1.753,5.459,2.631,8.414,2.631c2.355,0,4.696-0.553,6.857-1.676c4.855-2.549,7.909-7.6,7.909-13.092V175.989z
		" />
                </g>
              </svg>
            </div>
            <div style="place-self: center;" class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title">Export</h3>
            </div>
          </div>
        </div>
        <form [formGroup]="form" (ngSubmit)="onExport()">
          <div style="height: 240px">
            <formly-form [form]="form" [fields]="fields" [model]="model" (modelChange)="onModelChanged($event)"
              ></formly-form>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button type="submit"
              class="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">Save</button>
            <button type="button" (click)="close()"
              class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
    `,
  styles: [`

    /* Add this style to your component's styles */

    /* Add CSS styles only to dropdowns with the custom class */
.custom-dropdown .ng-dropdown-panel-items.scroll-host {
    overflow-y: scroll;
    height: 93px;
}

    `],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})

// ::ng-deep .ng-dropdown-panel {
//   height: 100px; /* Adjust the height based on your needs */
//   overflow-y: scroll;
// }

export class ExportTimeseriesModalComponent extends SimpleModalComponent<any, boolean | null>{
  exportIcon = EXPORT_ICON;
  model: any = {}
  selectedFile: string | null = null;
  makeDropdownScrollable: boolean = true;
  form: any = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  timeSeriesDetails: any;
  partitionKey: any;
  dataObject: any;
  objectSubject = new BehaviorSubject<{
    revisionId: string;
    id: string;
    timezone: string;
    name?: string | null;
    uom?: string | null;
    granularity: string;
    partitionKey: string;
  } | null>(null);
  
selectedObject: any;
  private exportConversionOptions$ = [
    { label: 'CSV (de)', value: 'csv_de', disabled: false },
    { label: 'CSV (en)', value: 'csv_en', disabled: false },
    { label: 'XLS', value: 'excel', disabled: false },
    { label: 'Endur Deal Import', value: 'endur_deal', disabled: true },
    { label: 'Endur Data Series Import', value: 'endur_dataseries', disabled: true },
  ];

  constructor(private apiService: ApiService) {
    super();
  }

  ngOnInit() {
    this.objectSubject.subscribe((object: any) => {
      this.selectedObject = object;
      if (object.objectType && (object.objectType == DataObjectTypeName.ContractProfile || object.objectType == DataObjectTypeName.GenerationProfile)) {
        const optionsToEnable = ['endur_deal', 'endur_dataseries']; // Add other options here if needed

        this.exportConversionOptions$.forEach(option => {
          if (optionsToEnable.includes(option.value)) {
            option.disabled = false;
          }
        });
      }
      this.fields = this.getConfig();
    });
    // this.fields = this.getConfig();
  }

  exportToEndurDeal(data: any[][], exportFileName: any) {
    const flatArray = data.flat();
    const headers = ['Time', 'Volume'];
    let dataArray = [headers, ...flatArray.map(item => Object.values(item))];
    const ws: XLSX.WorkSheet | any = XLSX.utils.aoa_to_sheet(dataArray);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
        for (let R = range.s.r + 1; R <= range.e.r; ++R) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            if (C === 0) {
                // Format for Time column
                ws[cellRef].t = "d";
                ws[cellRef].z = "dd.mm.yyyy hh:mm:ss";
            }
            //  else if (C === 1) {
            //     // Format for Volume column
            //     ws[cellRef].z = "0.00";
            // }
        }
    }
    XLSX.writeFile(wb, `${exportFileName}.xlsx`);
    this.close();
  }

  exportToEndurDataSeries(data: any[][], exportFileName: any) {
    const flatArray = data.flat();
    const headers = ['startDate', 'endDate', 'volume'];
    let dataArray = [headers, ...flatArray.map(item => Object.values(item))];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataArray);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${exportFileName}.xlsx`);
    this.close();
  }

  exportToXLSX(data: any[][], exportFileName: any) {
    const flatArray = data.flat();
    const headers = ['timestamp_local', 'value'];
    let dataArray = [headers, ...flatArray.map(item => Object.values(item))];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataArray);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${exportFileName}.xlsx`);
    this.close();
  }
  exportToCSV(dataobjects: any) {
    const flatArray = dataobjects.flat();
    const headers = ['timestamp_local', 'value'];
    const dataArray = [headers, ...flatArray];
    const csvData = dataArray.map((item: any) => {
      const values = Object.values(item)
        .filter(value => value !== '');  // Remove empty strings

      return values.join(',');
    }).join('\n');
    return csvData;
  }

  exportData(csvContent: any, exportFileName: any) {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${exportFileName}.csv`;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    this.close();
  }
  private formatValue(value: any, decimalSep: string): string {
    return value ? value.toString().replace('.', decimalSep) : '';
  }

  private convertToCSVGermanFormat(data: any[][]): string {
    const flatArray = data.flat();
    const headers = ['timestamp_local', 'value'];
    const dataArray = [headers, ...flatArray];
    const csvData = dataArray.map((item: any) => {
      const values = Object.values(item)
        .map(value => this.formatValue(value, ','))
        .filter(value => value !== '');  // Remove empty strings

      return values.join(';');
    }).join('\n');

    return csvData;
  }

  callExportOnSelection(selectedoption: any, res: any, exportedFileName: any, headerExport: boolean) {
    switch (selectedoption) {
      case 'csv_en':
        const internationalCSVData = this.exportToCSV(res);
        this.exportData(internationalCSVData, exportedFileName);
        return;
      case 'csv_de':
        const germanCSVData = this.convertToCSVGermanFormat(res);
        this.exportData(germanCSVData, exportedFileName);
        return;
      case 'excel':
        this.exportToXLSX(res, exportedFileName);
        return;
      case 'endur_deal':
        this.exportToEndurDeal(res, exportedFileName);
        return;
      case 'endur_dataseries':
        this.exportToEndurDataSeries(res, exportedFileName);
        return;
      default:
        throw new Error('Invalid format option');
    }
  }

  onExport() {
    if (this.form.invalid) {
      return;
    }
      let selectedoption = this.model.exportOptions.value;
    let exportedFileName: any = null;
    if (this.dataObject && (this.dataObject.objectMeta || this.dataObject.generationProfileInfo)) {
      if (this.dataObject.objectMeta && (this.dataObject.objectMeta.name || this.dataObject.parentObject.objectMeta.name)) {
        exportedFileName = (this.dataObject.objectMeta.name || this.dataObject.parentObject.objectMeta.name);
      } else if (this.dataObject.generationProfileInfo) {
        exportedFileName = this.dataObject.generationProfileInfo.name || (this.selectedObject.id || 'Exported_TS')
      }
    } else if (this.selectedObject.timeSeriesName) {
      exportedFileName = this.selectedObject.timeSeriesName;
    } else {
      exportedFileName = (this.selectedObject.id || 'Exported_TS');
    }
      if (selectedoption == 'endur_deal') {
        this.apiService
          .GetEndurDealTS(this.selectedObject!.id, this.selectedObject!.revisionId).subscribe((res: any) => {
            this.callExportOnSelection(selectedoption, res, exportedFileName, true);
          })
      } else if(selectedoption == 'endur_dataseries') {
        this.apiService
        .GetEndurDataSeriesTS(this.selectedObject!.id, this.selectedObject!.revisionId).subscribe((res: any) => {
          this.callExportOnSelection(selectedoption, res, exportedFileName, true);
        })
      } else {
        this.apiService
          .GetAllTSDataObjects(this.selectedObject!.id, this.selectedObject!.revisionId).subscribe((res: any) => {
            this.callExportOnSelection(selectedoption, res, exportedFileName, false);
          })
      }
  }

  onModelChanged(model: any) {
  }

  getDelimiterOptions() {
    return ConverterOptions.map((option) => {
      return {
        label: option.name,
        value: option.name,
        disabled: false,
      };
    });
  }


  private getConfig(): FormlyFieldConfig[] {
    return [
      requiredSelect<any>(
        'exportOptions',
        'Export Options',
        this.exportConversionOptions$,
        'label',
        undefined,
        {
          templateOptions: {
            placeholder: 'Select export option',
            maxOptions: 10
          },
        },
        '27686cfb-20c4-4b5d-aaf3-ae2f614af0f9',

      )
    ];
  }

  confirm() {
    this.result = true;
    this.close();
  }
}
