import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";
import { DetailPopupModalComponent } from "./detail-popup-modal.component";
// import { IconsModule } from "../icons/icons.component.module";
// import { CalculateImfPercentilesModalComponent } from "./calculate-imf-percentiles-modal.component";

@NgModule({
    declarations: [DetailPopupModalComponent],
    exports: [DetailPopupModalComponent],
    imports: [CommonModule, FormlyModule, ReactiveFormsModule],
  })
  export class DetailPopupModalComponentModule {}
  