import { CommonModule } from "@angular/common";
import { compileDeclareDirectiveFromMetadata } from "@angular/compiler";
import { Component, NgModule } from "@angular/core";
import { FieldType, FieldTypeConfig, FormlyModule } from "@ngx-formly/core";
import { Observable, of } from "rxjs";
import { TimeseriesGranularity, TimeseriesGranularityEnum } from "src/app/models/timeseries.model";

@Component({
    selector: 'formly-field-percentiles-imf',
    template: `
    <ng-container *ngIf="items | async as records">
        <div class="mt-1 overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300 md:rounded-lg">
                <thead class="bg-gray-50">
                    <tr>
                        <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
                        <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Monthly</th>
                        <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Yearly</th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                    <tr>
                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-right text-gray-500 sm:pl-6" >
                            IMF
                        </td>
                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                            <div class="flex h-5 items-center">
                                <!-- IMF Monthly checkbox -->     
                                <input id="imf-monthly" aria-describedby="IMF Monthly" name="imf-monthly" type="checkbox" [checked]="imfValue(MONTHLY)" (change)="imfCheckChanged(MONTHLY, $event)" class="h-4 w-4 rounded border-gray-300 text-uniper focus:ring-blue-500">
                            </div>               
                        </td>
                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                            <div class="flex h-5 items-center">
                                <!-- IMF Yearly checkbox -->                    
                                <input id="imf-yearly" aria-describedby="IMF Yearly" name="imf-yearly" type="checkbox" [checked]="imfValue(YEARLY)" (change)="imfCheckChanged(YEARLY, $event)" class="h-4 w-4 rounded border-gray-300 text-uniper focus:ring-blue-500">
                            </div>               
                        </td>
                    </tr>
                    <tr>
                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-right font-semibold text-gray-900 sm:pl-6" >
                            Percentiles
                        </td>
                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6"></td>
                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6"></td>
                    </tr>
                    <tr *ngFor="let record of records">
                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-right text-gray-500 sm:pl-6" >
                            <!-- calcspec column name -->
                            {{record}}
                        </td>
                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                            <div class="flex h-5 items-center">
                                <!-- Percentile Monthly checkbox -->                    
                                <input id="percentile-monthly" aria-describedby="Percentile Monthly" name="percentile-monthly" type="checkbox" [checked]="percentileValue(MONTHLY, record)" (change)="percentileCheckChanged(MONTHLY, record, $event)" class="h-4 w-4 rounded border-gray-300 text-uniper focus:ring-blue-500">
                            </div>               
                        </td>
                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                            <div class="flex h-5 items-center">
                                <!-- Percentile Yearly checkbox -->                    
                                <input id="percentile-yearly" aria-describedby="Percentile Yearly" name="percentile-yearly" type="checkbox" [checked]="percentileValue(YEARLY, record)" (change)="percentileCheckChanged(YEARLY, record, $event)" class="h-4 w-4 rounded border-gray-300 text-uniper focus:ring-blue-500">
                            </div>               
                        </td>
                    </tr>
                </tbody>
            </table>     
        </div>     
    <ng-container>    
    `,
  })
  export class PercentilesAndImfFormlyField extends FieldType<FieldTypeConfig> {
    YEARLY = TimeseriesGranularityEnum.Yearly;
    MONTHLY = TimeseriesGranularityEnum.Monthly;

    get items(): Observable<any> {
      if (!(this.to.options instanceof Observable)) {
        return of(this.to.options);
      }
      return this.to.options;
    }

    imfCheckChanged(granularity: TimeseriesGranularity, e: any): void {
        let val = {...this.formControl.value};
        val[`imf${granularity}`] = e.target.checked;
        this.formControl.setValue(val);
    }

    imfValue(granularity: TimeseriesGranularity): boolean {
        return this.formControl.value[`imf${granularity}`];
    }

    percentileCheckChanged(granularity: TimeseriesGranularity, column: string, e: any): void {
        let val = {...this.formControl.value};
        let percentileColumns = val[`percentile${granularity}`];
        if (e.target.checked === true) {
            if (!percentileColumns) {
                percentileColumns = [column];
            } else if (!percentileColumns.includes(column)) {
                percentileColumns.push(column);
            }
        } else {
            if (percentileColumns) {
                percentileColumns = [...percentileColumns.filter((i:string) => i !== column)]
            }
        }
        val[`percentile${granularity}`] = percentileColumns;
        this.formControl.setValue(val);
    }

    percentileValue(granularity: TimeseriesGranularity, column: string): boolean {
        let colArr = this.formControl.value[`percentile${granularity}`];
        return colArr && colArr.includes(column);
    }
  }
  
  @NgModule({
    declarations: [PercentilesAndImfFormlyField],
    exports: [PercentilesAndImfFormlyField],
    imports: [CommonModule, FormlyModule],
  })
  export class PercentilesAndImfFormlyFieldModule {}
  