import { Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  toRMVDDateString,
  toRMVDDateTimeString,
} from 'src/app/shared/utils/utils';

@Pipe({ name: 'rmvdDate' })
export class RmvdDatePipe implements PipeTransform {
  transform(value: string | null | undefined, p: string): string {
    if (value == null || value == undefined) return '';
    if (p == 'date') return toRMVDDateString(new Date(value));
    else if (p == 'timestamp') return toRMVDDateTimeString(new Date(value));
    return value;
  }
}

@NgModule({
  declarations: [RmvdDatePipe],
  exports: [RmvdDatePipe],
  imports: [CommonModule],
})
export class RmvdDatePipeModule {}
