import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RmvdDatePipeModule } from '../../pipes/rmvdDate.pipe';
import { ActionMenuComponentModule } from '../action-menu/action-menu.component.module';
import { DataObjectIconComponentModule } from '../dataobject-icon/dataobject-icon.component';
import { TagsComponentModule } from '../tags/tags.component.module';
import { TimeseriesPreviewComponentModule } from '../timeseries-preview/timeseries-preview.component.module';
import { ValuationTaskPreviewComponent } from './valuation-task-preview.component';

@NgModule({
  declarations: [ValuationTaskPreviewComponent],
  exports: [ValuationTaskPreviewComponent],
  imports: [
    CommonModule,
    ActionMenuComponentModule,
    DataObjectIconComponentModule,
    RmvdDatePipeModule,
  ],
})
export class ValuationTaskPreviewComponentModule {}
