import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";
import { IconsModule } from "../icons/icons.component.module";
import { CalculateImfPercentilesModalComponent } from "./calculate-imf-percentiles-modal.component";

@NgModule({
    declarations: [CalculateImfPercentilesModalComponent],
    exports: [CalculateImfPercentilesModalComponent],
    imports: [CommonModule, IconsModule, FormlyModule, ReactiveFormsModule],
  })
  export class CalculateImfPercentilesModalComponentModule {}
  