import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RmvdDatePipeModule } from '../../pipes/rmvdDate.pipe';
import { DataObjectIconComponentModule } from '../dataobject-icon/dataobject-icon.component';
import { ValuationRunResultsPreviewComponent } from './valuationrunresults-preview.component';

@NgModule({
  declarations: [ValuationRunResultsPreviewComponent],
  exports: [ValuationRunResultsPreviewComponent],
  imports: [CommonModule, DataObjectIconComponentModule, RmvdDatePipeModule],
})
export class ValuationRunResultsPreviewComponentModule {}
