<div class="line-chart relative w-full h-full" #container>
  <ng-template #defaultLegendTemplate let-data let-graphColours="graphColours">
    <ul class="flex list-none flex-col">
      <li
        *ngFor="let graphData of data; index as i; last as last"
        class="flex items-center"
        [ngClass]="{ 'mb-0': last, 'mb-3': !last }"
      >
        <span
          class="w-12 h-3 mr-3"
          [ngStyle]="{ 'background-color': graphColours[i] }"
        ></span>
        <span class="text-sm"> {{ graphData.label }} </span>
      </li>
    </ul>
  </ng-template>
  <div
    class="border-solid border border-gray-500 p-2 bg-white absolute hidden pointer-events-none line-chart__tooltip"
    #tooltip
  >
    <div class="line-chart__tooltip-value"></div>
    <div class="line-chart__tooltip-date"></div>
  </div>
  <div
    *ngIf="data && isLineChartDataCollection(data)"
    class="border-gray-300 border rounded-sm bg-white right-4 top-0 absolute m-2 p-2"
  >
    <ng-container
      [ngTemplateOutlet]="legendTemplate || defaultLegendTemplate"
      [ngTemplateOutletContext]="{
        $implicit: data,
        graphColours: graphColours
      }"
    ></ng-container>
  </div>
  <figure class="w-full h-full">
    <svg #chartImage class="w-full h-full"></svg>
  </figure>
</div>
