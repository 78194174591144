import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import {
  LineChartData,
  LineChartDataCollection,
  YAxisDetails,
} from 'src/app/models/lineChart.model';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent<T> implements OnChanges, OnInit, OnDestroy {
  @Input() timezone: string = 'Europe/Berlin'; // TODO: remove this default in future
  @Input() data: T[] | LineChartDataCollection<T> | null = null;
  @Input() xAccessor: ((data: T) => Date) | null = null;
  @Input() yAccessor: ((data: T) => number) | null = null;
  @Input() xDomain: [Date, Date] | null = null;
  @Input() yDomain: YAxisDetails[] | null = null;
  @Input() xTickFormat: ((data: Date, index: number) => string) | null = null;
  @Input() yTickFormat: ((data: number, index: number) => string) | null = null;
  @Input() showTooltip: boolean = true;
  @Input() yAxisLabel: string | null = '';
  @Input() renderAsSteps: boolean | null = null;

  @ContentChild('legendTemplate', { static: true })
  legendTemplate: TemplateRef<any> | null = null;

  @ViewChild('chartImage', { static: true })
  private svg?: ElementRef;

  @ViewChild('container', { static: true })
  private container!: ElementRef;

  @ViewChild('tooltip', { static: true })
  private tooltip?: ElementRef;
  private lineGenerator: Map<string | Symbol, d3.Line<T>> | null = null;
  private yAxis: d3.Axis<number>[] | null = null;
  private xAxis: d3.Axis<Date> | null = null;
  private observer: ResizeObserver | null = null;
  private containerGroup: d3.Selection<
    SVGGElement,
    unknown,
    null,
    undefined
  > | null = null;

  defaultSymbol = Symbol();

  @HostListener('window:resize')
  onWindowResize() {
    this.resizeChart();
  }

  private get defaultYScale() {
    return this.getYScale(this.defaultSymbol);
  }

  private get defaultLineGenerator() {
    return this.lineGenerator?.get(this.defaultSymbol);
  }

  private xScale: d3.ScaleTime<number, number> | null = null;

  private yScale: Map<
    string | YAxisDetails | Symbol,
    d3.ScaleLinear<number, number>
  > | null = null;

  private marginY = 50;
  private marginX = 50;
  private axisWidth = 50;
  private animationDuration = 500;
  graphColours = ['#0077d9', ...d3.schemeDark2];

  ngOnInit(): void {
    this.observer = new ResizeObserver(() => {
      this.resizeChart();
    });
    this.observer.observe(this.container.nativeElement);
  }

  ngOnChanges(): void {
    if (
      this.data &&
      this.xAccessor &&
      this.yAccessor &&
      this.xScale &&
      this.yScale &&
      this.lineGenerator
    ) {
      this.updateChart();
      return;
    }

    if (this.data && this.xAccessor && this.yAccessor) {
      this.buildChart();
    }
  }

  isLineChartDataCollection(data: unknown): data is LineChartDataCollection<T> {
    return (
      Array.isArray(data) &&
      data[0]?.data !== undefined &&
      data[0]?.label !== undefined
    );
  }

  /*
    - Draw the image
    - Draw the container
    - Create scales
    - Draw line
    - Draw the axis    
  */
  private buildChart() {
    if (this.data && this.xAccessor && this.yAccessor) {
      // Calculate XMargin based on number of yAxis to render
      this.setXMargin();

      this.containerGroup = d3
        .select(this.svg?.nativeElement)
        .append('g')
        .classed('root', true)
        .attr('transform', `translate(${this.marginX},${this.marginY})`);

      // Create Scale
      this.createXScale();
      this.createYScale();

      // Mutate scale objects
      this.setXDomain();
      this.setXRange();
      this.setYDomain();
      this.setYRange();

      // Create Line generators to draw lines
      this.createLineGenerators();

      // Draw Shapes
      this.drawLines();

      // Create Axis callbacks
      this.createXAxis();
      this.createYAxis();

      // Draw Axis using the callbacks
      this.yAxis!.forEach((yAxis, index) => {
        this.containerGroup!.append('g')
          .classed('y-axis', true)
          .attr('transform', `translate(${-(this.axisWidth * index)}, 0)`)
          .call(yAxis);
      });

      this.containerGroup
        .append('g')
        .classed('x-axis', true)
        .attr('transform', `translate(0, ${this.innerHeight()})`)
        .call(this.xAxis!);

      // Draw the y Label
      this.drawYLabel();

      // Add Events for tooltip
      if (this.showTooltip && !this.isLineChartDataCollection(this.data)) {
        this.addTooltips();
      }
    }
  }

  /*
    - Update the scale domain
    - Update the line
    - Update the Axis    
  */
  private updateChart() {
    if (!this.canProceedWithResizeOrUpdate()) {
      return;
    }

    // Calculate XMargin based on number of yAxis to render
    this.setXMargin();

    this.containerGroup = d3
      .select(this.svg?.nativeElement)
      .select<SVGGElement>('g')
      .attr('transform', `translate(${this.marginX},${this.marginY})`);

    // Mutate scale objects
    this.setXDomain();
    this.setXRange();

    // Create Y Scale
    this.createYScale();

    // Mutate Y scale objects
    this.setYDomain();
    this.setYRange();

    // Create Line generators to draw lines
    this.createLineGenerators();

    // Draw Lines using the updated Scales
    this.drawLines();

    // Draw Axis
    this.createYAxis();

    // Draw Axis using the updated Scales
    this.drawAxis();

    // Draw the y Label
    this.drawYLabel();

    // Add Events for tooltip, in case there is no collection of data on update
    if (this.showTooltip && !this.isLineChartDataCollection(this.data)) {
      this.addTooltips();
    }
  }

  /*
    - Update the scales range
    - Update the line
    - Update the Axis    
  */
  private resizeChart() {
    if (!this.canProceedWithResizeOrUpdate()) {
      return;
    }

    // Mutate scale objects
    this.setXRange();
    this.setYRange();

    // Draw Lines using the updated Scales
    this.drawLines();

    // Draw Axis using the updated Scales
    this.drawAxis();

    // Draw the y Label
    this.drawYLabel();

    // Update the tooltip overlay
    this.updateTooltipOverlayDimensions();
  }

  private innerWidth(): number {
    return this.svg?.nativeElement.clientWidth - this.marginX * 2;
  }

  private innerHeight(): number {
    return this.svg?.nativeElement.clientHeight - this.marginY * 2;
  }

  private canProceedWithResizeOrUpdate(): boolean {
    return (
      !!this.data &&
      !!this.xAccessor &&
      !!this.yAccessor &&
      !!this.xScale &&
      !!this.yScale &&
      !!this.lineGenerator
    );
  }

  private createXScale() {
    this.xScale = d3.scaleTime();
  }

  private createYScale() {
    this.yScale = new Map();
    if (this.yDomain?.length) {
      this.yDomain.forEach((data) => {
        const yScale = d3.scaleLinear();
        (data.ids || [this.defaultSymbol]).forEach((id) => {
          this.yScale!.set(id, yScale);
        });
        this.yScale!.set(data, yScale);
      });
    } else {
      // Default Scale
      this.yScale.set(this.defaultSymbol, d3.scaleLinear());
    }
  }

  private setXMargin() {
    if (this.yDomain?.length) {
      this.marginX = this.axisWidth * this.yDomain.length;
    } else {
      this.marginX = this.axisWidth;
    }
  }

  private createLineGenerators() {
    this.lineGenerator = new Map();
    if (this.yDomain?.length) {
      this.yDomain.forEach((data) => {
        (data.ids || [this.defaultSymbol]).forEach((id) => {
          const renderAsSteps =
            this.isLineChartDataCollection(this.data) &&
            this.data.find((d) => d.id === id)?.renderAsSteps;
          if (this.getYScale(id)) {
            this.lineGenerator!.set(
              id,
              this.createLineGenerator(this.getYScale(id)!, renderAsSteps)
            );
          }
        });
      });
    } else {
      if (this.defaultYScale) {
        this.lineGenerator.set(
          this.defaultSymbol,
          this.createLineGenerator(this.defaultYScale, this.renderAsSteps)
        );
      }
    }
  }

  private createXAxis() {
    this.xAxis = d3.axisBottom<Date>(this.xScale!);
    if (this.xTickFormat) {
      this.xAxis.tickFormat((d, index) => this.xTickFormat!(d, index));
    }
  }

  private createYAxis() {
    this.yAxis = [];
    if (this.yDomain?.length) {
      this.yDomain.forEach((data) => {
        this.yAxis?.push(d3.axisLeft<number>(this.getYScale(data)!));
      });
    } else {
      if (this.defaultYScale) {
        this.yAxis?.push(d3.axisLeft<number>(this.defaultYScale));
      }
    }

    if (this.yTickFormat) {
      this.yAxis.forEach((yAxis) =>
        yAxis.tickFormat((d, index) => this.yTickFormat!(d, index))
      );
    }
  }

  private createLineGenerator(
    yScale: d3.ScaleLinear<number, number>,
    renderAsSteps?: boolean | null
  ) {
    return d3
      .line<T>()
      .x((d) => this.xScale!(this.xAccessor!(d)))
      .y((d) => yScale(this.yAccessor!(d)))
      .curve(renderAsSteps ? d3.curveStepAfter : d3.curveMonotoneX);
  }

  private getYScale(key: string | YAxisDetails | Symbol) {
    return this.yScale?.get(key);
  }

  private drawAxis() {
    const animationDuration = this.animationDuration;
    const axisWidth = this.axisWidth;
    this.containerGroup!.select<SVGGElement>('g.x-axis')
      .transition()
      .duration(this.animationDuration)
      .attr('transform', `translate(0,${this.innerHeight()})`)
      .call(this.xAxis!);

    this.containerGroup!.selectAll('g.y-axis')
      .data(this.yAxis!)
      .join('g')
      .classed('y-axis', true)
      .each(function (yAxis, index) {
        d3.select(this as SVGGElement)
          .transition()
          .duration(animationDuration)
          .attr('transform', `translate(${-(axisWidth * index)}, 0)`)
          .call(yAxis);
      });
  }

  private updateTooltipOverlayDimensions() {
    const svg = d3.select(this.svg?.nativeElement);
    svg
      .select('.tooltip-overlay')
      .attr('width', this.innerWidth())
      .attr('height', this.innerHeight());
  }

  private addTooltips() {
    if (!this.isLineChartDataCollection(this.data) && this.defaultYScale) {
      const container = d3.select(this.svg?.nativeElement).select('g');
      const tooltip = d3.select(this.tooltip?.nativeElement);
      const tooltipDot = container
        .append('circle')
        .attr('r', 5)
        .classed('fill-amber-500 stroke-black', true)
        .attr('stroke-width', 2)
        .style('opacity', 0)
        .style('pointer-events', 'none');

      container
        .append('rect')
        .classed('tooltip-overlay', true)
        .attr('width', this.innerWidth())
        .attr('height', this.innerHeight())
        .style('opacity', 0)
        .on('touchmouse mousemove', (event) => {
          const mousePos = d3.pointer(event);
          const date = this.xScale!.invert(mousePos[0]);

          const bisector = d3.bisector(this.xAccessor!).left;
          const index = bisector(this.data! as T[], date);
          const dataToShow = this.data![index - 1] as T;

          if (!dataToShow) {
            return;
          }

          tooltipDot!
            .style('opacity', 1)
            .attr('cx', this.xScale!(this.xAccessor!(dataToShow)))
            .attr('cy', this.defaultYScale!(this.yAccessor!(dataToShow)))
            .raise();

          tooltip
            .style('display', 'block')
            .style(
              'top',
              this.defaultYScale!(this.yAccessor!(dataToShow)) - 60 + 'px'
            )
            .style('left', this.xScale!(this.xAccessor!(dataToShow)) + 'px');

          const numberFormatter = d3.format('.2f');

          tooltip
            .select('.line-chart__tooltip-value')
            .text(`${numberFormatter(this.yAccessor!(dataToShow))}`);

          const dateFormatter = (d: Date): string =>
            DateTime.fromJSDate(d, { zone: this.timezone }).toFormat(
              'yyyy-MM-dd HH:mm:ss ZZZ'
            );

          tooltip
            .select('.line-chart__tooltip-date')
            .text(`${dateFormatter(this.xAccessor!(dataToShow))}`);
        })
        .on('mouseleave', () => {
          tooltipDot.style('opacity', 0);
          tooltip.style('display', 'none');
        });
    }
  }

  private drawYLabel() {
    const labelsToShow = [];
    if (this.yDomain?.length) {
      this.yDomain.forEach((data) => {
        labelsToShow.push(data.label);
      });
    } else {
      labelsToShow.push(this.yAxisLabel);
    }

    this.containerGroup!.selectAll('text.y-label')
      .data(labelsToShow)
      .join('text')
      .attr('x', -this.innerHeight() / 2)
      .attr('y', (d, index) => -(this.axisWidth * index + 30))
      .attr('fill', 'black')
      .classed('y-label', true)
      .classed('text-xs', true)
      .text((d) => d)
      .style('transform', 'rotate(270deg)')
      .style('text-anchor', 'middle');
  }

  private setXDomain() {
    if (this.isLineChartDataCollection(this.data) && this.xDomain) {
      this.xScale!.domain(this.xDomain).nice();
    } else {
      if (!this.isLineChartDataCollection(this.data)) {
        this.xScale!.domain(
          this.xDomain ??
            (d3.extent(this.data!, this.xAccessor!) as unknown as [Date, Date])
        ).nice();
      }
    }
  }

  private setYDomain() {
    if (this.isLineChartDataCollection(this.data) && this.yDomain) {
      this.yDomain.forEach((data) => {
        this.getYScale(data)?.domain(data.domain).nice();
      });
    } else {
      if (!this.isLineChartDataCollection(this.data)) {
        this.defaultYScale
          ?.domain(
            this.yDomain?.[0]?.domain ??
              (d3.extent(this.data!, this.yAccessor!) as unknown as [
                number,
                number
              ])
          )
          .nice();
      }
    }
  }

  private setXRange() {
    this.xScale!.range([0, this.innerWidth()]);
  }

  private setYRange() {
    for (const [, value] of this.yScale!) {
      value.range([this.innerHeight(), 0]);
    }
  }

  private drawLines() {
    const dataToPlot = this.getDataToPlot();
    this.containerGroup!.selectAll('path.line')
      .data(dataToPlot)
      .join('path')
      .attr('fill', 'none')
      .attr('stroke-width', 2)
      .classed('line', true)
      .attr('stroke', (_d, i) => this.graphColours[i])
      .transition()
      .duration(this.animationDuration)
      .attr('d', (d) => {
        const lineGenerator =
          this.lineGenerator?.get(d.id) || this.defaultLineGenerator;
        return lineGenerator?.(d.data) || '';
      });
  }

  private getDataToPlot() {
    return this.isLineChartDataCollection(this.data)
      ? this.data.map((lineChartData) => lineChartData)
      : [{ data: this.data, id: '', label: '' } as LineChartData<T>];
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }
}
