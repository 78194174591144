import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-input',
  template: `
  <div class="flex h-5 items-center m-1">
    <input
      type="checkbox"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [class.input-is-invalid]="showError"
      class="h-4 w-4 rounded border-gray-300 text-uniper focus:ring-blue-500"
    />
  </div>
  `,
})
export class CheckboxFormlyFieldInput extends FieldType<FieldTypeConfig> {
}

@NgModule({
  declarations: [CheckboxFormlyFieldInput],
  exports: [CheckboxFormlyFieldInput],
  imports: [CommonModule, FormlyModule, ReactiveFormsModule],
})
export class CheckboxFormlyFieldInputModule {}
