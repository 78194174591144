export enum Granularity {
    Hourly = 'Hourly',
    Daily = 'Daily', 
    Weekly = 'Weekly', 
    Monthly = 'Monthly', 
    Quarterly = 'Quarterly', 
    Yearly = 'Yearly'
}

export enum SettlementPeriod {
    Month = 'Month', 
    Quarter = 'Quarter', 
    Year = 'Year'
}

// export const SettlementPeriod: any = {
//     Month : 'Month', 
//     Quarter : 'Quarter', 
//     Year : 'Year'
// }

interface SettlementPeriodOptionsMap {
    [key: string]: string;
}


export const SettlementPeriodOptionsMap: any = {
    'Month': 'Monthly',   // API value for Month
    'Quarter': 'Quarterly', // API value for Quarter
    'Year': 'Yearly'       // API value for Year
};