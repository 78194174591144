import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'formly-field-buttongroup',
  template: `
    <span
      class="relative z-0 inline-flex rounded-md shadow-sm"
      [class.input-is-invalid]="showError"
    >
      <ng-container *ngIf="items | async as opts">
        <button
          *ngFor="let item of opts; let first = first; let last = last"
          type="button"
          class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-700 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
          (click)="onItemSelected(item)"
          (blur)="onBlur()"
          [formlyAttributes]="field"
          [ngClass]="{
            'rounded-l-md': first,
            'rounded-r-md': last,
            'border-uniper': itemIsSelected(item),
            'bg-uniper': itemIsSelected(item),
            'text-white': itemIsSelected(item),
            'bg-white': !itemIsSelected(item),
            'text-gray-700': !itemIsSelected(item),
            'disabled:hover:bg-uniper': itemIsSelected(item),
            'disabled:hover:bg-white': !itemIsSelected(item),
            'disabled:hover:text-gray-300': true,
            'disabled:text-gray-300': true
          }"
        >
          {{ to['bindValue'] ? item[to['bindValue']] : item }}
        </button>
      </ng-container>
    </span>
  `,
})
export class ButtonGroupFormlyFieldInput extends FieldType<FieldTypeConfig> {
  get items() {
    if (!(this.to.options instanceof Observable)) {
      return of(this.to.options);
    }
    return this.to.options;
  }

  onBlur() {
    this.formControl.markAsTouched();
  }

  onItemSelected(item: any): void {
    this.formControl.setValue(item);
  }

  itemIsSelected(item: any): boolean {
    return this.formControl.value == item;
  }
}

@NgModule({
  declarations: [ButtonGroupFormlyFieldInput],
  exports: [ButtonGroupFormlyFieldInput],
  imports: [CommonModule, FormlyModule],
})
export class ButtonGroupFormlyFieldInputModule {}
