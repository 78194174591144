<header class="flex bg-white shadow-sm h-[50px] items-center">
  <app-data-management-header
    class="w-full"
    *ngIf="isActive('/data-management')"
  ></app-data-management-header>
  <app-ppa-inventory-header
  class="w-full"
  *ngIf="isActive('/ppa-inventory')"
></app-ppa-inventory-header>
  <app-dashboard-header
    class="w-full"
    *ngIf="isActive('/dashboard')"
  ></app-dashboard-header>
  <app-upsert-object-header
    class="w-full"
    [editMode]="isActive('/manage')"
    *ngIf="
      isActive(
        '/addobject',
        '/manage',
        '/valuationtask/execute'
      )
    "
  ></app-upsert-object-header>
  <app-upsert-ppa-object-header
    class="w-full"
    [editMode]="isActive('/ppamanageobject')"
    *ngIf="
      isActive(
        '/addppaobject',
        '/ppamanageobject'
      )
    "
  ></app-upsert-ppa-object-header>
  <app-valuation-cockpit-header
    class="w-full"
    *ngIf="isActive('/valuationcockpit')"
  ></app-valuation-cockpit-header>
  <app-valuation-result-header
    class="w-full"
    *ngIf="isActive('/valuation-results')"
  ></app-valuation-result-header>
  <app-background-jobs-header
    class="w-full"
    *ngIf="isActive('/background-jobs')"
    >
  </app-background-jobs-header>
  <app-graph-basket-header
    class="w-full"
    *ngIf="isActive('/graph-basket')"
    >
  </app-graph-basket-header>
</header>
